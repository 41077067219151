import { Component, Inject } from '@angular/core';
import {
  Address,
  CustomAreaPostalCodes,
  IArea,
  IUserMembershipItem,
  Organization,
  Service, SocialRelationKindTypes, User, UserImportMandatory,
  UserImportOptional, UserSettings
} from '@shared/model';
import { OrganizationService, OrgServiceService, SettingsService } from '@core/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IDataTableConfig, ITableData, ModalComponent } from '@shared/components';
import { MatStepper } from '@angular/material/stepper';
import { LabelService, UserService, UserPromptsService } from 'app/core';
import { ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IObjectMap } from '@shared/interface';
import { extend, flatten } from 'lodash';
import * as XLSX from 'xlsx';
import moment from 'moment';

// import firebase from 'firebase/compat/app';
import { take } from 'rxjs/operators';
import { AddressService } from '@core/services/address.service';
import { WrongAddressData, WrongPostalCodeDialogComponent } from '@shared/components/wrong-postal-code-dialog/wrong-postal-code-dialog.component';
import { isValid } from 'iban';
import { CustomAreaService } from '@core/services/custom-area.service';

// type DocumentReference = firebase.firestore.DocumentReference;

export interface ExcelMappingField {
  dbField: string;
  excelField: string;
  selected: boolean;
  required: boolean;
}

export enum ImportUserTypes {
  users = 1,
  coaches
}

@Component({
  selector: 'app-import-users-dialog',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.scss']
})
export class ImportUsersComponent extends ModalComponent implements OnInit {
  @ViewChild('userImport') fileInput: ElementRef;
  public user: User;
  public service: Service;
  public usersImportRole: string;
  public form: FormGroup;
  public excelFilePath = '';
  public fileSelectEvent: Event;
  public stepper: MatStepper;
  public excelData: IObjectMap<string>[] = [];
  public usersToImport: ITableData[] = [];
  public usersNotToImport: ITableData[] = [];
  public excelFields: string[] = [];
  public dbExcelMappings: ExcelMappingField[] = [];
  public mandatoryFields = Object.keys(UserImportMandatory);
  public optionalFields = Object.keys(UserImportOptional);
  public labels: any = {};
  public isLinear: boolean;
  private wrongPostalCodes: WrongAddressData[] = [];
  protected counter: number;
  protected totalUsers = 0;
  protected inProcess: boolean;
  protected customAreaPostalCodes: CustomAreaPostalCodes[];
  private org: Organization;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ImportUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IObjectMap<any>,

    private userPromptsService: UserPromptsService,
    private labelService: LabelService,
    private orgServiceService: OrgServiceService,
    private fb: FormBuilder,
    private userService: UserService,
    private addressService: AddressService,
    private settingsService: SettingsService,
    private customAreaService: CustomAreaService,
    private organizationService: OrganizationService
  ) {
    super(dialogRef, breakpointObserver);
    this.user = data.user;
    this.service = data.service;
    this.usersImportRole = data.usersImportRole;
  }

  async ngOnInit() {
    this.dialogRef.updateSize('90vw', 'auto');
    this.getExtraData();
    this.labels = (await this.labelService.getLabels('app-import-users-dialog')).data;
    this.user = await this.userService.getCurrentUser().pipe(take(1)).toPromise();
    const settings: UserSettings = this.user.settings || {};
    const settingsMap: IObjectMap<string> = settings.excelMapping ? settings.excelMapping.user : null;
    const defaultMap: IObjectMap<string> = {};
    extend(defaultMap, UserImportMandatory);
    extend(defaultMap, UserImportOptional);

    const userMap: IObjectMap<string> = settingsMap || defaultMap;
    this.createDbExcelMappings(userMap);
    this.createForm();
  }

  private getExtraData() {
    this.customAreaService.getCustomAreasNocache().then(customAreas => {
      Promise.all(customAreas.map(customAreas => {
        return this.customAreaService.getCustomAreasPostalCodesNocache(customAreas.id)
      })).then(data => {
        this.customAreaPostalCodes = flatten(data);
      })
    });

    this.organizationService.getCurrentOrganization().pipe(take(1)).toPromise()
      .then(org => {
        this.org = org;
      })
  }

  getTableConfigUsersToImport(): IDataTableConfig {
    return {
      data: this.usersToImport,
      displayProperties: this.getTableHeaders(),
      rowOptions: [],
      allowSelection: false,
      actionButtonLabel: 'Start Import',
      displayHeaders: true
    };
  }

  getTableConfigUsersNotToImport(): IDataTableConfig {
    // TODO fix function
    return {
      data: this.usersNotToImport,
      displayProperties: this.getTableHeaders(),
      rowOptions: [],
      allowSelection: false,
      actionButtonLabel: this.labels.update_users,
      displayHeaders: true
    };
  }

  createDbExcelMappings(dbMapping: IObjectMap<string>) {
    this.dbExcelMappings = [];
    this.mandatoryFields.concat(this.optionalFields).forEach((field) => {
      dbMapping = dbMapping || {};
      const excelField = dbMapping[field] || '';
      this.dbExcelMappings.push({
        dbField: field,
        excelField: excelField,
        selected: !!dbMapping[field],
        required: this.mandatoryFields.indexOf(field) > -1
      });
    });
  }

  isAllSelected(dbExcelMappings: ExcelMappingField[]) {
    let count = 0;
    dbExcelMappings.forEach((row) => {
      if (row.selected) {
        count++;
      }
    });

    return count === dbExcelMappings.length;
  }

  isPartiallySelected(dbExcelMappings: ExcelMappingField[]) {
    let count = 0;
    dbExcelMappings.forEach((row) => {
      if (row.selected) {
        count++;
      }
    });

    return count > 0 && count < dbExcelMappings.length;
  }

  updateMapping(row: ExcelMappingField, value: string) {
    row.excelField = value;
    row.selected = value !== '-';

    return row;
  }

  onFileSelect() {
    this.fileInput.nativeElement.click();
    this.form.patchValue({
      filePath: this.excelFilePath
    });
    if (this.fileSelectEvent) {
      this.onFileSelected(this.fileSelectEvent, this.stepper);
    }
  }

  // This function uses the XLSX library to read excel sheets or csv files
  onFileSelected(evt: Event | any, stepper: MatStepper) {
    this.fileSelectEvent = evt;
    this.stepper = stepper;

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target.files.length !== 1) {
      throw new Error(this.labels.no_multiple_files);
    }

    this.excelFilePath = `${target.files[0].name}`;
    this.form.patchValue({
      filePath: this.excelFilePath
    });

    const reader: FileReader = new FileReader();
    reader.onload = (e: Event | any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* get the excel data in the right format*/
      this.excelData = this.formatExcelDataAsKeyedJson(data[0], data.slice(1));
      /*get the excel field names */
      this.excelFields = Object.keys(this.excelData[0]);

      this.stepper.next();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  // checks if the row is valid
  userValid(user: User) {
    let valid = true;

    if (user) {
      this.mandatoryFields.forEach((key: string) => {
        if (!user[key]) {
          valid = false;
        }
      });
    }

    return valid;
  }

  // this function converts the excel row data into a json file of keyed objects
  formatExcelDataAsKeyedJson(headerObject: IObjectMap<string>, data: IObjectMap<string>[]) {
    return data.map((item) => {
      const keyedItem = {};
      Object.keys(headerObject).forEach((index) => {
        const header = headerObject[index];
        keyedItem[header] = item[index];
      });

      return keyedItem;
    });
  }

  // this gets the excel fields option
  getExcelFields(dbField: string) {
    const fields = this.excelFields.slice();

    if (this.mandatoryFields.indexOf(dbField) === -1) {
      fields.unshift('-');
    }

    return fields;
  }

  // this function, takes the mapped fields settings and use it to get the users objects
  async mapData(stepper: MatStepper) {
    this.counter = 0;
    this.inProcess = true;
    this.counter++;
    this.usersNotToImport = [];
    this.usersToImport = [];
    // we compute the list of users to be imported
    const users = this.excelData
      .map(row => this.mapRowToUser(row))
      .filter((user: User) => this.userValid(user)).map(user => {
        if (user.phone && !user.phone.toString()?.startsWith('+')) user.phone = (`+${user.phone}`).trim();
        if (user.phone2 && !user.phone2?.toString()?.startsWith('+')) user.phone2 = (`+${user.phone2}`).trim();
        user.termsAccepted = true;
        return user as User;
      });

    if (users.every(async (user: User) => user.iban && !isValid(user.iban) ? false : true)) {

      new Promise<User[]>(async (resolve) => {
        this.totalUsers = users.length;
        const u: User[] = [];
        for await (const user of users) {
          user.address.postalcode = user.address.postalcode?.trim();
          user.address.letter = user.address.letter?.trim();
          const existingUser = await this.getExistingUser(user);
          user.customAreaId = this.customAreaPostalCodes.find(customAreaPostalCode => customAreaPostalCode.postalCode == user.address.postalcode.substring(0, 4))?.customAreaId || '';
          if (existingUser) {
            if (existingUser.address && existingUser.address.postalcode != user.address.postalcode) {
              const userAddressData = await this.getUserAddress(user.address.postalcode, user.address.number, user.address.letter)
              user.address = userAddressData?.address || null;
              user.area = userAddressData?.area || null;
            }
            // create a new copy of array in order to trigger table render
            this.usersNotToImport = this.usersNotToImport.slice();
            this.usersNotToImport.push(this.mapHelper(extend(existingUser, user)));
          } else {
            const userAddressData = await this.getUserAddress(user.address.postalcode, user.address.number, user.address.letter)
            user.address = userAddressData?.address || null;
            user.area = userAddressData?.area || null;
            let newUser: User = new User();
            newUser = extend(newUser, user);
            // create a new copy of array in order to trigger table render
            this.usersToImport = this.usersToImport.slice();
            this.usersToImport.push(this.mapHelper(newUser));
          }
          u.push(user);
          this.counter++;
        }
        this.inProcess = false;
        if (this.wrongPostalCodes.length) {
          this.userPromptsService.showDialogue(WrongPostalCodeDialogComponent, { data: this.wrongPostalCodes }, null, false, { width: '400px' });
        }
        resolve(u);
      }).then(() => {
        this.saveUserMapping();
        // go to the next step
        stepper.next();
      })
    } else {
      this.userPromptsService.showAlertDialogue(this.labels.incorrect_iban_header, this.labels.incorrect_iban_message);
    }
  }

  private mapHelper(user: User) {
    return {
      _metadata: {
        originalData: user
      },
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone: user.phone,
      phone2: user.phone2,
      gender: user.gender,
      birthday: user.birthday,
      number: user.address?.number,
      letter: user.address?.letter,
      postalcode: user.address?.postalcode,
      code: user.code,
      usernote: user.usernote,
      userRemark: user.userRemark,

    } as ITableData
  }

  saveUserMapping() {
    // save information about mapping to DB
    this.user.settings = this.user.settings || {};
    this.user.settings.excelMapping = this.user.settings.excelMapping || {};
    this.user.settings.excelMapping.user = this.getMappedFields();

    this.userService.updateUser(this.user);
  }

  // Checks if a user has been imported before
  getExistingUser(excelUser: User) {
    return new Promise<User>(async (resolve) => {
      // let result: User;
      let existedUser: User;

      if (excelUser.code) {
        existedUser = await this.userService.getUserByCode(excelUser.code);
      } else if (excelUser.email) {
        existedUser = await this.userService.getUserByEmail(excelUser.email);
      } else {
        if (excelUser.phone) {
          existedUser = await this.userService.getUserByFirstNameAndPhone(excelUser.firstname, excelUser.phone);
        } else {
          existedUser = await this.userService.getUserByFirstNameAndBirthday(excelUser.firstname, excelUser.address.postalcode);
        }
      }

      // if (existedUser) {
      //   excelUser.id = existedUser.id;
      //   result = merge(existedUser, excelUser);
      // }
      return resolve(existedUser);
    });
  }

  // this returns the fields that were mapped
  getTableHeaders() {
    const result = Object.keys(this.getMappedFields());
    result.unshift('select');

    return result.filter(prop => !(prop.includes('contact') || prop.includes('partner') || prop.includes('membership')));
  }

  // returns an object that maps the db fields to the excel fields
  getMappedFields() {
    const mappedFieldsObj = {};
    this.dbExcelMappings.forEach((row) => {
      if (row.excelField !== '-') {
        mappedFieldsObj[row.dbField] = row.excelField;
      }
    });

    return mappedFieldsObj;
  }

  // This maps a row in the excel sheet to a user object
  mapRowToUser(excelUser: IObjectMap<string>) {
    const mappedUser: Partial<User> = {
      address: {} as any,
      userContactPerson: {} as any,
      userContactPerson2: {} as any,
      userPartner: {} as any,
      memberships: {} as any
    };
    let updateContactPersonRegistration = false;
    let updateContactPersonRegistration2 = false;
    let updatePartnerRegistration = false;
    let membership: IUserMembershipItem = {} as any

    this.dbExcelMappings.forEach(async (row) => {
      if (row.excelField !== '-') {
        if (row.dbField === 'letter' ||
          row.dbField === 'number' ||
          row.dbField === 'postalcode') {

          (mappedUser.address[row.dbField] as any) = excelUser[row.excelField];
        } else if (row.dbField === 'birthday' && excelUser[row.excelField]) {
          mappedUser.birthday = moment(excelUser[row.excelField]).format('YYYY-MM-DD');
        } else if (row.dbField === 'gender' && excelUser[row.excelField]) {
          mappedUser.gender = excelUser[row.excelField].toLowerCase().indexOf('m') === 0 ? 'male' : 'female';
        } else if (row.excelField !== '-' && row.dbField.includes('contactPerson') && !row.dbField.includes('contactPerson2')) {
          if (row.dbField == UserImportOptional.contactPersonFirstName) {
            mappedUser.userContactPerson['firstName'] = excelUser[row.excelField] || '';
            if (excelUser[row.excelField]) updateContactPersonRegistration = true;
          } else if (row.dbField == UserImportOptional.contactPersonLastName) {
            mappedUser.userContactPerson['lastName'] = excelUser[row.excelField] || '';
            if (excelUser[row.excelField]) updateContactPersonRegistration = true;
          } else if (row.dbField == UserImportOptional.contactPersonPhone) {
            mappedUser.userContactPerson['phone'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.contactPersonRemark) {
            mappedUser.userContactPerson['remark'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.contactPersonEmail) {
            mappedUser.userContactPerson['email'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.contactPersonSocialRelationKind) {
            mappedUser.userContactPerson['socialRelationKind'] = excelUser[row.excelField] ? SocialRelationKindTypes[excelUser[row.excelField]] : null
          }
        } else if (row.dbField.includes('partner')) {
          if (row.dbField == UserImportOptional.partnerFirstName) {
            mappedUser.userPartner['firstname'] = excelUser[row.excelField] || '';
            if (excelUser[row.excelField]) updatePartnerRegistration = true;
          } else if (row.dbField == UserImportOptional.partnerLastName) {
            mappedUser.userPartner['lastname'] = excelUser[row.excelField] || '';
            if (excelUser[row.excelField]) updateContactPersonRegistration = true;
          } else if (row.dbField == UserImportOptional.partnerPhone) {
            mappedUser.userPartner['phone'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.partnerRemark) {
            mappedUser.userPartner['remark'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.partnerEmail) {
            mappedUser.userPartner['email'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.partnerBirthday) {
            mappedUser.userPartner['birthday'] = moment(excelUser[row.excelField]).format('YYYY-MM-DD');
          } else if (row.dbField == UserImportOptional.partnerGender) {
            mappedUser.userPartner['gender'] = excelUser[row.excelField].toLowerCase().indexOf('m') === 0 ? 'male' : 'female';
          }
        } else if (row.dbField.includes('contactPerson2')) {
          if (row.dbField == UserImportOptional.contactPerson2FirstName) {
            mappedUser.userContactPerson2['firstName'] = excelUser[row.excelField] || '';
            if (excelUser[row.excelField]) updateContactPersonRegistration2 = true;
          } else if (row.dbField == UserImportOptional.contactPerson2LastName) {
            mappedUser.userContactPerson2['lastName'] = excelUser[row.excelField] || '';
            if (excelUser[row.excelField]) updateContactPersonRegistration2 = true;
          } else if (row.dbField == UserImportOptional.contactPerson2Phone) {
            mappedUser.userContactPerson2['phone'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.contactPerson2Remark) {
            mappedUser.userContactPerson2['remark'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.contactPerson2Email) {
            mappedUser.userContactPerson2['email'] = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.contactPerson2SocialRelationKind) {
            mappedUser.userContactPerson2['socialRelationKind'] = excelUser[row.excelField] ? SocialRelationKindTypes[excelUser[row.excelField]] : null;
          }
        } else if (row.dbField.includes('membership')) {
          if (row.dbField == UserImportOptional.membershipId) {
            membership.membershipId = excelUser[row.excelField];
          } else if (row.dbField == UserImportOptional.membershipKind) {
            membership.membershipKind = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.membershipTitle) {
            membership.membershipTitle = excelUser[row.excelField] || '';
          } else if (row.dbField == UserImportOptional.membershipFrom) {
            membership.memberFrom = excelUser[row.excelField] ? moment(excelUser[row.excelField] || '').format('YYYY-MM-DD') : null;
          } else if (row.dbField == UserImportOptional.membershipUntil) {
            membership.memberUntil = excelUser[row.excelField] ? moment(excelUser[row.excelField] || '').format('YYYY-MM-DD') : null;
          } else if (row.dbField == UserImportOptional.membershipNumber) {
            membership.membershipNumber = excelUser[row.excelField] || '';
          }
        } else {
          mappedUser[row.dbField] = excelUser[row.excelField];
        }
      }
    });
    const matched = this.org.memberships.find(m => m.id == membership.membershipId);
    if (matched) mappedUser.memberships[membership.membershipId] = membership;

    mappedUser.userContactPerson['contactPersonRegistration'] = updateContactPersonRegistration ? true : false;
    mappedUser.userContactPerson2['contactPersonRegistration'] = updateContactPersonRegistration2;
    mappedUser.userPartner.partnerRegistration = updatePartnerRegistration;
    return mappedUser;
  }

  getMappedRows() {
    return this.dbExcelMappings.filter((row) => {
      return row.excelField !== '-';
    });
  }

  createForm() {
    this.form = this.fb.group({
      filePath: ['', Validators.required],
    });
  }

  // This function uploads the selected users to the DB
  uploadUsers(data: ITableData[]) {
    if (this.inProcess) return;
    let users = data.map(data => data._metadata.originalData as User);
    if (this.wrongPostalCodes.length) {
      const usersWithWrongPostalCodes = users.find(u => {
        const address = u.address;
        return !!this.wrongPostalCodes.find(w => w.postalCode == address.postalcode && w.number == address.number && w.letter == address.letter);
      })
      if (usersWithWrongPostalCodes) return this.userPromptsService.showToast(this.labels.file_has_incorrect_data);
    }
    this.counter = 0;
    // we update the users based on the import type
    users = this.updateTypeInUsersUser(users);

    this.userPromptsService.showConfirmDialogue(
      this.labels.are_you_sure_to_import_users,
      this.labels._translate('n_users_will_be_imported', { n: users.length }),
      async (res) => {
        if (res) {
          this.inProcess = true;
          await Promise.all(
            users.map(async (user: User) => {
              this.counter++;
              return this.userService.saveUser(user).then((userId: string) => {
                if (this.service && this.usersImportRole) {
                  this.service[this.usersImportRole][userId] = true;
                }
                return userId;
              })
            })
          )
          if (this.service) {
            await this.orgServiceService.updateService(this.service);
          }
          this.inProcess = false;
          this.userPromptsService.showToast(
            this.labels._translate('n_users_imported', { n: users.length }),
            null
          );
          this.dialogRef.close();
        }
      }
    );
  }

  private async getUserAddress(postalCode: string, number: number, letter: string) {
    const addressData: {address:Address, area: IArea} = { address: null, area: null };
    if (postalCode && postalCode.length === 6 && number && number > 0) {
      try {
        const [data, area] = await Promise.all([
          this.addressService.getAddressByPostalCode(postalCode, number, letter).pipe(take(1)).toPromise(),
          this.settingsService.getAreaFromPostalCode(postalCode)
        ])
        if (data) {
          addressData.address = {
            city: data?.address?.locality || data.city,
            street: data?.address?.street || data.street,
            postalcode: data?.address?.postcode || data.postcode,
            number: data?.address?.buildingNumber || data.houseNumber,
            letter: data?.address?.buildingNumberAddition || data.houseNumberAddition,
            geo: {
              latitude: data?.location?.latitude || data.latitude,
              longitude: data?.location?.longitude || data.longitude
            },
            mailLines: data?.mailLines || [],
            country: "NLD"
          }
        }
        if (area) {
          addressData.area = area;
        }
        return addressData;
      } catch (error) {
        if (error.status == 404) {
          this.wrongPostalCodes.push({ postalCode, number, letter });
          this.userPromptsService.showToast(this.labels._translate('wrong', { msg: `wrong postal code ${postalCode} ${number}, ${letter}` }));
        } else {
          console.log(JSON.stringify(error))
          this.userPromptsService.showToast(this.labels.error_fetching_address_by_postal_code);
        }
        const d: any = {
          postalcode: postalCode,
          number: number,
          letter: letter
        }
        addressData.address = d;
        return addressData;
      }
    } else {
      this.userPromptsService.showToast(this.labels.incorrect_postalcode_or_number);
      return addressData;
    }
  }

  // This function updates the selected users in the DB
  updateUsers(data: ITableData[]) {
    if (this.inProcess) return;
    let users = data.map(data => data._metadata.originalData as User);
    if (this.wrongPostalCodes.length) {
      const usersWithWrongPostalCodes = users.find(u => {
        const address = u.address;
        return !!this.wrongPostalCodes.find(w => w.postalCode == address.postalcode && w.number == address.number && w.letter == address.letter);
      })
      if (usersWithWrongPostalCodes) return this.userPromptsService.showToast(this.labels.file_has_incorrect_data);
    }
    this.counter = 0;
    // we update the users based on the import type
    users = this.updateTypeInUsersUser(users);

    this.userPromptsService.showConfirmDialogue(
      this.labels.are_you_sure_to_update_users,
      this.labels._translate('n_users_will_be_updated', { n: users.length }),
      async (res) => {
        if (res) {
          this.inProcess = true;
          await Promise.all(users.map(async (user) => {
            this.counter++;
            if (this.service && this.usersImportRole) {
              this.service[this.usersImportRole][user.id] = true;
              this.orgServiceService.updateService(this.service);
            }
            return this.userService.updateUser(user);
          }))
          this.inProcess = false;
          this.userPromptsService.showToast(
            this.labels._translate('n_users_updated', { n: users.length }),
            null
          );
          this.dialogRef.close();
        }
      }
    );
  }

  // based on the option the user selected for importing users we update the field for that
  // user type
  // if Import type is user, we just return the users
  // if Import type is coaches
  updateTypeInUsersUser(users: User[]): User[] {
    return users.map((user: User) => {
      switch (this.data.importType) {
        case ImportUserTypes.coaches: {
          user.isCoach = true;
          break;
        }
      }

      return user;
    });
  }

  removeUser(user) {
    this.usersToImport = this.usersToImport.filter((data) => {
      if (user.email) {
        return user.email !== data.email;
      } else {
        return (user.firstname !== data.firstname
          || user.lastname !== data.lastname
          || user.birthday !== data.birthday);
      }
    });
  }
}
