<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{labels.availability_selector}}</div>
  </div>

  <div class="modal_body" #modal_body>
    <div class="p-3" fxLayout="column" fxLayoutGap="15px">
      <div class="chips_selector">
        <mat-chip-listbox aria-label="Week days">
          <ng-container *ngFor="let day of weekdays">
            <mat-chip-option color="accent" [selected]="day.prop === selectedWeekday" (click)="selectWeekday(day)">
              {{day.name}}
            </mat-chip-option>
          </ng-container>
          <mat-chip-option color="accent" [selected]="!selectedWeekday" (click)="selectWeekday()">
            {{labels.show_all}}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.search}}</mat-label>
          <input matInput [(ngModel)]="availabilityFilter.searchText" (ngModelChange)="onSearchUsers()">
        </mat-form-field>

        <mat-form-field appearance="outline" class="small">
          <mat-label>{{labels.gender}}</mat-label>
          <mat-select [(ngModel)]="availabilityFilter.gender" (ngModelChange)="onFilterUsers()">
            <mat-option [value]="null" selected>{{labels.show_all}}</mat-option>
            <mat-option value="male" selected>{{labels.male}}</mat-option>
            <mat-option value="female">{{labels.female}}</mat-option>
            <mat-option value="unknown">{{labels.unkown}}</mat-option>
          </mat-select>
        </mat-form-field>

        @if (customAreas?.length) {
        <mat-form-field appearance="outline" class="small">
          <mat-label>{{labels.custom_area}}</mat-label>
          <mat-select [(ngModel)]="availabilityFilter.customArea" (ngModelChange)="onFilterUsers()">
            <mat-option [value]="null">{{labels.show_all}}</mat-option>
            @for (customArea of customAreas; track $index) {
            <mat-option [value]="customArea.id">{{customArea.title}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        } @else {
        <mat-form-field appearance="outline" class="small">
          <mat-label>{{labels.district}}</mat-label>
          <mat-select [(ngModel)]="availabilityFilter.district" (ngModelChange)="onFilterUsers()">
            <mat-option [value]="null">{{labels.show_all}}</mat-option>
            @for (district of districts; track $index) {
            <mat-option [value]="district.code">{{district.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        }
        @if (data.field === 'executor') {
        <div>
          <mat-slide-toggle [(ngModel)]="availabilityFilter.status"
            (ngModelChange)="onFilterUsers()">{{labels.active}}</mat-slide-toggle>
        </div>}
      </div>

      <div class="">
        <div *ngIf="users.length === 0" class="p-5" fxLayout="row" fxLayoutAlign="center center">
          {{labels.no_user_available}}
        </div>

        <div class="list_container" *ngIf="users.length > 0">

          <div class="user_item" style="display: grid; grid-template-columns: 52% 43% 5%; gap: 0;" [id]="user.id"
            (mouseenter)="hoverEffect(user.id, true)" (mouseleave)="hoverEffect(user.id, false)"
             [ngClass]="{'border-primary': selected?.id === user.id}" *ngFor="let user of users">
            <div>
              <div (click)="userSelected(user)" class="cursor_pointer" fxLayout="row" fxFlex="250px"
                fxLayoutAlign="start start" fxLayoutGap="15px">
                <img [src]="user.picture || '/assets/images/user.png'" [alt]="user.fullname" class="thumbnail">
                <div fxFlex="stretch" fxLayout="column" fxLayoutGap="5px">
                  <div>{{user.fullname}}</div>
                  <div class="fs-0-8">{{userSubtitle(user)}}</div>
                </div>
              </div>
              <div *ngIf="field === 'coordinator' else executorsOnly" [fxFlex]="selectedWeekday ? '180px' : '250px'"
                fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                <div class="box_item" *ngFor="let session of availabilitySessions"
                  [ngClass]="{'bg-primary': isActive(user.availability?.days, session)}"></div>
              </div>
              <ng-template #executorsOnly>
                <div  style="min-width: 120px; margin-bottom: auto;">
                  <button class="custom-class" mat-mini-fab style="width: 30px; height: 30px; margin: 5px;"
                    (click)="openExecutorRequests(user, exeStatue)" *ngFor="let exeStatue of executorsStatus[user.id]"
                    [ngClass]="['status-bg-'+(exeStatue.status || '')]">
                    {{[exeStatue.count]}}
                  </button>
                </div>
              </ng-template>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" class="fs-0-8 carriage_returns pl-2">
              <div fxLayout="column">
                @if (user.availability?.plannedLeave?.length) {
                <div>
                  @for (plannedLeave of getPlannedLeave(user.availability?.plannedLeave); track $index) {
                  <div>
                    <span>{{plannedLeave.remark}}: </span>
                    <span>{{plannedLeave.from | date: 'shortDate'}}</span> -
                    <span>{{plannedLeave.to | date: 'shortDate'}}</span>
                  </div>
                  }
                </div>
                }
                <ng-container *ngIf="commentText(user) as txt">
                  <div>
                    <div>
                      {{textShortener[user.id] ? txt : txt.substr(0, 150)}} &nbsp;
                      <span *ngIf="txt.length > 150" (click)="textShortener[user.id] = !textShortener[user.id]"
                        class="link_item">{{textShortener[user.id] ? labels.show_less : labels.show_more}}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div fxLayout="column" fxLayoutGap="0px" style="margin-left: auto;">
              <button mat-icon-button (click)="openAvailabilityDialog(user)"
                [style.color]="hasOnGoingPlannedLeave(user.availability?.plannedLeave) ? 'red' : 'green'">
                <mat-icon>{{user.availability?.availability === 1 ? 'event_available' : 'event_busy'}}</mat-icon>
              </button>

              <button mat-icon-button (click)="openExecutorOfferingsDialog(user)">
                <mat-icon>work_outlined</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>