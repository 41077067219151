import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IArea } from 'app/shared/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiResponse } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(
        private http: HttpClient,
    ) { }

    public getAreaFromPostalCode(postalCode: string): Promise<IArea> {
        return new Promise<IArea>((resolve) => {
            const config = {
                params: {
                    postalCode,
                    limit: '1',
                },
                headers: {
                    'Authorization': 'Bearer ' + environment.asToolApiKey
                }
            };

            this.http.get<ApiResponse<IArea[]>>(`${environment.asToolApi}/postal-codes`, config)
                .pipe(take(1)).toPromise().then(r => {
                    resolve(r.data ? r.data[0] || null : null);
                });
        });
    }
}
