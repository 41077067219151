.app-rate-item {
  cursor: pointer;
  padding: 0.3em;
  filter: sepia(0.5);
}
.app-rate-item:first-child {
  padding-left: 0;
}
.app-rate-item:last-child {
  padding-right: 0;
}

.app-rate-item:hover {
  filter: sepia(0);
}
app-rate {
  display: inline-block;
}
