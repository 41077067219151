import { DocumentLog } from './documentLog';
import { RoleTypes } from '../enum';

export enum UsageActionTypes {
    create_user = 1,
    view_user,
    create_request,
    view_request,
    complete_request,
    create_institution,
    view_institution,
    update_network_partner,
    submit_service_registration,
    accept_service_registration,
    reject_service_registration,
    create_inventory_item,
    update_inventory_item,
    approve_reservation,
    reject_reservation,
    create_reservation,
    update_reservation,
    accept_request,
    reject_request,
    assign_executor_request,
    disconnect_executor_request,
    assign_coordinator_request,
    disconnect_coordinator_request,
    assign_team_member_request,
    disconnect_team_member_request
}

export enum UsageActionKinds {
    view = 1,
    update
}

export interface UserActionTo {
    userId: string;
    firstName: string;
    lastName: string;
}

export interface UserActionBy {
    userId: string;
    firstName: string;
    lastName: string;
    orgId: string;
    role: RoleTypes;
    workingAreaId: string;
    serviceId?: string;
}

export class UsageActivity {
    id: string;
    action: UsageActionTypes;
    hour: number;
    minute: number;
    year: number;
    month: number;
    day: number;
    timestamp: Date;
    actionBy: UserActionBy;
    actionTo: UserActionTo;
    requestId?: string;
    log?: DocumentLog;
    details: string;
    usageActionKind: UsageActionKinds
}
