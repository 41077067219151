export const USER_SEARCH_FIELDS = [
  'firstname',
  'lastname',
  '$email',
  'birthday',
  'address.postalcode',
  '$phone',
  '$phone2',
  '$address.city',
  'address.number',
  'address.street',
  'networkPartner.networkPartnerType',
  '$personalNumber',
  '$id',
  'userPartner.firstname',
  'userPartner.lastname',
  '$code',
];

