<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.history_activity}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <div class="content">
            <div fxLayout="row" fxLayoutAlign="end" class="mb-4">
                <mat-button-toggle-group name="view type" aria-label="View Types" [(ngModel)]="mode"
                (ngModelChange)="onModeChange()">
                    <mat-button-toggle [value]="UsageActionKinds.update">{{labels.updates}}</mat-button-toggle>
                    <mat-button-toggle [value]="UsageActionKinds.view">{{labels.views}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            @if (docTableConfig?.data?.length) {
                <data-table [configOptions]="docTableConfig"></data-table>

                @if (lastRef) {
                    <div fxLayout="row" fxLayoutAlign="center">
                        <button [disabled]="isLoading" mat-stroked-button (click)="fetchMore()">
                            {{isLoading ? labels.loading : labels.fetch_more}}
                        </button>
                    </div>
                }
            }
        </div>
    </div>
</app-modal>