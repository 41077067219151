.panel-body {
  padding: 15px;
}

.selector_controls {
  padding: 5px;
  padding-right: 20px;
}

.scroll-body {
  height: 62vh;
  overflow: auto;
}

.form-field {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.disabled_element {
  pointer-events: none;
  opacity: 0.4;
}
