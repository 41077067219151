<app-modal [alignFooterStart]="true">
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.recently_seen}}</span>
    </div>

    <div class="modal_body" #modal_body>
        @if (isLoading) {<mat-progress-bar mode="indeterminate"></mat-progress-bar>}
        <div class="m-3">
            @if (users?.length) {
            <div class="list">
                @for (user of users; track $index) {
                <div class="list-box" fxLayout="row" fxLayoutGap="10px" (click)="gotoUser(user)">
                    <div fxFlex="stretch">{{user.fullname}}</div>
                    <div style="height: 50px;">
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </div>
</app-modal>