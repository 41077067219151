<div fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 30px;">
  <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="20px">
    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-card style="min-height: 245px;" fxLayout="column">
        <mat-card-content>
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="stretch" *ngIf="customer.address as addr">
              <div>{{addr.street}} {{addr.number}} {{addr.letter}}</div>
              <div>{{addr.postalcode}} {{addr.city}}</div>
              <div *ngIf="customer.area">{{customer.area?.district.text}} - {{customer.area?.neighbourhood.text}}</div>
              <div style="margin-top: 15px;">
                <span *ngIf="customer.gender && customer.gender != 'unknown'">{{ labels[customer.gender] }} </span>
                <span *ngIf="age() > 0"></span> <span *ngIf="customer.birthday">
                  ({{age()}})</span>
              </div>
              <div style="margin-top: 15px;" *ngIf="customer.userPartner && customer.userPartner.partnerRegistration">
                {{customer.userPartner.firstname}} {{customer.userPartner.lastname}} ({{labels.partner}})</div>
              <div *ngIf="customer.userContactPerson && customer.userContactPerson.firstName">
                {{customer.userContactPerson.firstName}} {{customer.userContactPerson.lastName}}
                {{customer.userContactPerson.socialRelationKind ?
                '('+labels[socialKind[customer.userContactPerson.socialRelationKind]]+')' : ''}}
                <mat-icon color="accent" [inline]="true" style="font-size: 18px;"
                  (click)="openContactPersonDetailDialog('userContactPerson')">info_outline</mat-icon>
              </div>
              <div *ngIf="customer.userContactPerson2 && customer.userContactPerson2.firstName">
                {{customer.userContactPerson2.firstName}} {{customer.userContactPerson2.lastName}}
                {{customer.userContactPerson2.socialRelationKind ?
                '('+labels[socialKind[customer.userContactPerson2.socialRelationKind]]+')' : ''}}
                <mat-icon color="accent" [inline]="true" style="font-size: 18px;"
                  (click)="openContactPersonDetailDialog('userContactPerson2')">info_outline</mat-icon>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start end">
              <thumbnail class="cursor_pointer" (click)="openImageModal(customer.picture)" height="60px" width="60px"
                [image]="customer.picture"></thumbnail>
              <span class="profile_pix_icon">
                <mat-icon [inline]="true" (click)="updateProfilePicture()">
                  edit
                </mat-icon>
              </span>
            </div>
          </div>


          <div style="padding-top: 10px;">{{customer.usernote}}</div>
        </mat-card-content>
        <div fxFlex="stretch"></div>

        <mat-card-footer fxLayout="row" fxLayoutAlign="space-between" style="padding: 0 10px 10px;">
          <div fxLayout="row" fxLayoutGap="15px">
            <button mat-icon-button color="primary" (click)="openGoogleMaps()" [disabled]="!canOpenGoogleMaps()">
              <mat-icon>location_on</mat-icon>
            </button>

            <button mat-icon-button color="accent" (click)="openUserRequests()">
              <mat-icon>handshake</mat-icon></button>
          </div>
          <button style="margin-top: auto;" mat-button color="primary" [disabled]="!canCoordEdit" (click)="openEditUser()">{{labels.edit}}</button>
        </mat-card-footer>
      </mat-card>
    </div>

    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-card style="min-height: 245px;" fxLayout="column">
        <mat-card-content>
          <form [formGroup]="personInfoForm" fxLayout="column" fxLayoutGap="15px" role="form" *ngIf="personInfoForm">
            <div fxLayout="row" fxLayoutGap="10px">

              <div fxLayout="column" style="width: 100%;" fxLayoutGap="15px">
                <div>
                  <app-phone-input [value]="personInfoForm.value.phone" [placeholder]="labels.phone" [readonly]="!canCoordEdit"
                    (update)="updatePhoneField(personInfoForm, 'phone', $event)" [hint]="{text: customer.phoneRemark, hide: personalInfoShowMore}">
                  </app-phone-input>
                </div>

                @if (personInfoForm.value.phone2 || personalInfoShowMore) {
                <div>
                  <app-phone-input [value]="personInfoForm.value.phone2" [placeholder]="labels.phone2"
                    (update)="updatePhoneField(personInfoForm, 'phone2', $event)" [readonly]="!canCoordEdit"
                    [hint]="{text: customer.phone2Remark, hide: personalInfoShowMore}">
                  </app-phone-input>
                </div>
                }
              </div>
            </div>

            @if (personalInfoShowMore) {
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.phone_remark}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="phoneRemark" [readonly]="!canCoordEdit">
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.phone2_remark}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="phone2Remark" [readonly]="!canCoordEdit">
              </mat-form-field>
            </div>
            }

            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email}}</mat-label>
                <mat-hint *ngIf="!personalInfoShowMore">{{customer.emailRemark}}</mat-hint>
                <div fxLayout="row">
                  <input autocomplete="off" matInput type="email" formControlName="email" [readonly]="!canCoordEdit">
                  <mat-icon style="max-height: fit-content; margin-right: 5px;" matSuffix *ngIf="customer.email "
                    class="cursor_pointer" (click)="copyToClipboard(customer.email || '')">content_copy</mat-icon>
                </div>
              </mat-form-field>

              @if (personInfoForm.value.email2 || personalInfoShowMore) {
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email2}}</mat-label>
                <mat-hint *ngIf="!personalInfoShowMore">{{customer.email2Remark}}</mat-hint>
                <div fxLayout="row">
                  <input autocomplete="off" matInput type="email" formControlName="email2" [readonly]="!canCoordEdit">
                  <mat-icon style="max-height: fit-content; margin-right: 5px;" *ngIf="customer.email2" matSuffix
                    class="cursor_pointer" (click)="copyToClipboard(customer.email2 || '')">content_copy</mat-icon>
                </div>
              </mat-form-field>
            }
            </div>

            @if (personalInfoShowMore) {
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email_remark}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="emailRemark" [readonly]="!canCoordEdit">
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.email2_remark}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="email2Remark" [readonly]="!canCoordEdit">
              </mat-form-field>
            </div>
            }
          </form>
        </mat-card-content>
        <div fxFlex="stretch"></div>


        <mat-card-footer fxLayout="row" fxLayoutAlign="space-between" style="padding: 0 10px 10px; margin-top: auto;">
          <button mat-icon-button color="primary" (click)="openAvailabilityDialog()" [disabled]="!canCoordEdit">
            <mat-icon>event_available</mat-icon>
          </button>

          <app-card-action-button [form]="personInfoForm"
            [showMoreConfig]="{showMore:personalInfoShowMore, inUse: true }"
            (onShowMore)="personalInfoShowMore = $event" (discard)="setupPersonalInfoForm()"
            [hideSaveBtn]="hideSaveBtn"
            (save)="savePersonlInfoForm()"></app-card-action-button>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>

  <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="15px">
    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-expansion-panel (opened)="isGetMailingList = true">
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.additional_information}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column">

          <form [formGroup]="extraInfoForm" fxLayout="column" fxLayoutGap="10px" role="form" *ngIf="extraInfoForm">

            <mat-form-field appearance="outline">
              <mat-label>{{labels.user_important_note}}</mat-label>
              <textarea matInput maxlength="130" cdkTextareaAutosize minRows="2" #input
                formControlName="usernote" [readonly]="!canCoordEdit"></textarea>
              <mat-hint align="end">{{input.value.length}}/130</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{labels.remarks}}</mat-label>
              <textarea matInput maxlength="255" cdkTextareaAutosize minRows="2" #input
                formControlName="userRemark" [readonly]="!canCoordEdit"></textarea>
              <mat-hint align="end">{{input.value.length}}/255</mat-hint>
            </mat-form-field>

            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.personal_number}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="personalNumber" [readonly]="!canCoordEdit">

                <mat-error *ngIf="extraInfoForm.get('personalNumber').errors?.wrongNumber">
                  {{extraInfoForm.get('personalNumber')?.errors?.wrongNumber}}
                </mat-error>

              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.code}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="code" [readonly]="!canCoordEdit">
              </mat-form-field>
            </div>
            @if (extraInfoShowMore) {
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.iban}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="iban" [readonly]="!canCoordEdit">

                <mat-error *ngIf="extraInfoForm.get('iban').errors?.wrongNumber">
                  {{extraInfoForm.get('iban')?.errors?.wrongNumber}}
                </mat-error>

              </mat-form-field>
            </div>

            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.mailinglists}}</mat-label>
              <mat-select multiple formControlName="mailinglistSubscriptions" [disabled]="!canCoordEdit">
                <mat-option *ngFor="let m of mailinglists" [value]="m.id"
                  [disabled]="!m.userCanSubscribe || !loggedInUser.isOrgAdmin">
                  {{m.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            }
          </form>

          <!-- <div fxFlex="stretch"></div> -->
          <mat-card-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
            @if (extraInfoForm?.pristine) {
            <button mat-button color="primary" style="width: max-content;"
              (click)="extraInfoShowMore = !extraInfoShowMore">{{extraInfoShowMore ? labels.show_less :
              labels.show_more}}</button>
            }
            @if (extraInfoForm?.dirty) {
            <button mat-button color="primary" (click)="setupExtraInfoForm()">{{labels.cancel}}</button>
            }
            <button mat-raised-button [disabled]="extraInfoForm?.invalid || extraInfoForm?.pristine || savingExtraForm"
              color="primary" (click)="saveExtraInfoForm()">{{labels.save}}</button>
          </mat-card-actions>
        </div>
      </mat-expansion-panel>
    </div>

    <div [style]="{width: isMobile ? '100%' : '49%'}">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.appointments}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column">
          <div class="content">
            <mat-chip-listbox>
              <mat-chip-option [matTooltip]="appointment.title" class="chip_style"
                *ngFor="let appointment of appointments" selected="true" [color]="'default'">
                <ng-container *ngIf="appointment.id">
                  <span class="chip_text">
                    {{ appointment.date | date:'short'}}
                  </span>
                  <mat-icon class="close" [matMenuTriggerFor]="menu">more_vert</mat-icon>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editAppointment(appointment)" [disabled]="!canCoordEdit">
                      <mat-icon>edit</mat-icon>
                      <span>{{ labels.edit }}</span>
                    </button>
                    <button mat-menu-item (click)="deleteAppointment(appointment)" [disabled]="!canCoordEdit">
                      <mat-icon>delete</mat-icon>
                      <span>{{ labels.delete }}</span>
                    </button>
                  </mat-menu>
                </ng-container>

                <ng-container *ngIf="!appointment.id">
                  {{labels.loading}}...
                </ng-container>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div fxLayout="row" fxLayoutAlign="end">
            <button (click)="newAppointment()" [disabled]="!canCoordEdit" class="actions-button" mat-button color="primary">{{labels.add}}</button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>


  <div *ngIf="fromPage === 'userPage'" fxLayoutGap="20px" fxLayout="row" fxLayout.xs="column">
    <div fxFlex="stretch">

      <mat-expansion-panel *ngIf="customer">
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.log_info}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column" class="settings_content">
          <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 20px;">
            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.created_at}}</div>
              <div>{{toDate(customer.log.createdAt)}}</div>
            </div>

            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.modified_at}}</div>
              <div>{{toDate(customer.log.updatedAt)}}</div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.created_by}}</div>
              <div>{{createdBy?.fullname}}</div>
            </div>

            <div fxFlex="stretch" fxLayout="column">
              <div class="text-primary">{{labels.modified_by}}</div>
              <div>{{updatedBy?.fullname}}</div>
            </div>
          </div>
        </div>

        <mat-card-actions>
          <div fxLayout="row" fxLayoutAlign="end">
            <button mat-icon-button (click)="openUsageDocModal()">
              <mat-icon>history</mat-icon>
            </button>
          </div>
        </mat-card-actions>
      </mat-expansion-panel>
    </div>

    <div fxFlex="stretch">
      <mat-expansion-panel *ngIf="this.org?.settings?.accountingSystem?.id == 'afas'" (opened)="getAfasUserByEmail()">
        <mat-expansion-panel-header>
          <div class="card-title" style="margin-bottom: 0;">{{labels.afas}}</div>
        </mat-expansion-panel-header>

        <div fxLayout="column">
          @if (connectingAfas) {
          <div style="margin: 5px 0;"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
          }
          @if (afasForm) {
          <form [formGroup]="afasForm">
            <div fxLayout="row" fxLayoutGap="15px">
              <mat-form-field appearance="outline">
                <mat-label>{{labels.number}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="number">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{labels.afas_id}}</mat-label>
                <input autocomplete="off" matInput type="text" formControlName="id">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between">
              <div style="margin-bottom: 20px;">
                <mat-slide-toggle color="primary"
                  formControlName="isExecutor">{{labels.is_afas_executor}}</mat-slide-toggle>
              </div>
              <div *ngIf="customer?.afasDetails?.connected"
                [style]="{width: '20px', height: '20px', 'background-color': isUserConnectedToAfas ? 'green' : 'red', 'border-radius': '10px'}">
              </div>
            </div>

          </form>
          }
        </div>

        <mat-card-actions fxLayout="row" fxLayoutAlign="space-between">
          <button mat-button [disabled]="connectingAfas" color="primary" [disabled]="!canCoordEdit" (click)="toggleAfasConnect()">
            {{customer?.afasDetails?.connected ? labels.disconnect : labels.connect}}
          </button>

          <button mat-raised-button [disabled]="!customer?.afasDetails?.connected || !afasForm.dirty" color="primary"
            [disabled]="!canCoordEdit" (click)="updateAfasUser()">
            {{labels.save}}
          </button>
        </mat-card-actions>
      </mat-expansion-panel>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center" style="margin: 10px 0;">
    <button  mat-stroked-button color="warning" *ngIf="fromPage !== 'requestPage'" [disabled]="(!workingArea?.settings?.coordinatorSettings?.canAddUser && userRole.roleType == 2)|| isDeletingUser"
      (click)="openDeleteUserDialog()">{{labels.delete_user}}</button>
  </div>
</div>