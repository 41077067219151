import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, computed, inject, Inject, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { Employability, Service, User } from '@models/model';
import { Sub } from '@shared/subscriptions';

@Component({
  selector: 'app-executor-offerings',
  templateUrl: './executor-offerings.component.html',
  styleUrl: './executor-offerings.component.scss'
})
export class ExecutorOfferingsComponent implements OnInit, OnDestroy {

  public labels = this.labelService.defaultProvider();
  protected form: FormGroup;
  public user: User;
  protected service: Service;
  private sub = new Sub();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly announcer = inject(LiveAnnouncer);
  readonly maxDescriptionLen = 140;
  readonly executorOfferings = signal<string[]>([]);
  private allOfferings: string[] = [];
  protected filteredOfferings: Signal<string[]>;

  readonly employability = signal<string[]>([]);
  private allAbilities: string[] = [];
  protected filteredAbilities: Signal<string[]>;

  constructor(
    private dialogRef: MatDialogRef<ExecutorOfferingsComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) protected data: { user: User, service: Service },
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
    private utilitiesService: UtilitiesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = this.data.user;
    this.service = this.data.service;
    this.labels = (await this.labelService.getLabels('app-executor-offerings')).data;

    this.executorOfferings.set(this.user.roles.services[this.service.id].serviceOffering || [])
    this.allOfferings = this.service?.settings?.subServices || [];
    this.filteredOfferings = computed(() => this.allOfferings.filter(o => !this.executorOfferings().includes(o)));

    this.employability.set(this.user.roles.services[this.service.id].employability || []);
    this.allAbilities = this.utilitiesService.getValuesForEnum(Employability);
    this.filteredAbilities = computed(() => this.allAbilities.filter(a => !this.employability().includes(a)));

    this.setupForm();
  }

  private setupForm() {
    this.form = this.fb.group({
      serviceOffering: [],
      employability: [],
      remark: [this.user.roles.services[this.service.id].remark]
    })

    this.sub.add(
      this.form.get('serviceOffering').valueChanges.subscribe(value => {
        this.filteredOfferings = computed(() => {
          const currentOfferings = value.toLowerCase();
          return currentOfferings
            ? this.allOfferings.filter(offering => offering.toLowerCase().includes(currentOfferings) && !this.executorOfferings().includes(offering))
            : this.allOfferings.filter(o => !this.executorOfferings().includes(o))
        });
      }),
      this.form.get('employability').valueChanges.subscribe(value => {
        this.filteredAbilities = computed(() => {
          const currentAbilities = value.toLowerCase();
          return currentAbilities
            ? this.allAbilities.filter(employability => employability.toLowerCase().includes(currentAbilities) && !this.employability().includes(employability))
            : this.allAbilities.filter(a => !this.employability().includes(a))
        });
      })
    )
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      this.executorOfferings.update(offerings => [...offerings, value]);
    }
    // Clear the input value
    this.form.get('serviceOffering').setValue('');
  }

  remove(offering: string): void {
    this.executorOfferings.update(offerings => {
      const index = offerings.indexOf(offering);
      if (index < 0) {
        return offerings;
      }
      offerings.splice(index, 1);
      this.announcer.announce(`Removed ${offering}`);
      return [...offerings];
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.executorOfferings.update(offering => [...offering, event.option.viewValue]);
    this.form.get('serviceOffering').setValue('');
    event.option.deselect();
  }


  addEmployability(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      this.employability.update(ability => [...ability, value]);
    }
    // Clear the input value
    this.form.get('employability').setValue('');
  }

  removeEmployability(ability: string): void {
    this.employability.update(abilities => {
      const index = abilities.indexOf(ability);
      if (index < 0) {
        return abilities;
      }
      abilities.splice(index, 1);
      this.announcer.announce(`Removed ${ability}`);
      return [...abilities];
    });
  }

  selectedEmployability(event: MatAutocompleteSelectedEvent): void {
    this.employability.update(abilities => [...abilities, event.option.viewValue]);
    this.form.get('employability').setValue('');
    event.option.deselect();
  }


  protected save() {
    const model = this.form.value;
    this.user.roles.services[this.service.id].serviceOffering = this.executorOfferings();
    this.user.roles.services[this.service.id].employability = this.employability() as Employability[];
    this.user.roles.services[this.service.id].remark = model.remark;
    this.userService.updateUser(this.user).then(() => {
      this.dialogRef.close(this.user);
    }).catch(error => {
      this.userPromptsService.showToast(error.message);
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
