import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { IDataTableConfig, ITableData } from '../data-table/data-table.component';
import { NgSub } from 'ng-sub';
import { Role, RoleTypes, UsageActionKinds, UsageActionTypes, UsageActivity } from '@models/index';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, UsageService } from '@core/services';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

@Component({
  selector: 'app-usage-display-model',
  templateUrl: './usage-display-model.component.html',
  styleUrls: ['./usage-display-model.component.scss']
})
export class UsageDisplayModelComponent implements OnInit, OnDestroy {

  public docTableConfig: IDataTableConfig;
  public labels: any = {};
  private sub = new NgSub();
  public role: Role;
  protected mode: UsageActionKinds = UsageActionKinds.update;
  protected UsageActionKinds = UsageActionKinds;
  protected lastRef: any;
  private usages: UsageActivity[] = [];
  protected isLoading = false;
  private limit = 30;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { userId: string, requestId?: string },
    private labelService: LabelService,
    private usageService: UsageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-usage-display-model')).data;
    this.fetchUsages();
  }

  private fetchUsages() {
    this.isLoading = true;
    this.usageService.getUsageCollectionGroup({ userId: this.data.userId, limit: this.limit, mode: this.mode, addDocRef: true, lastRef: this.lastRef, requestId: this.data.requestId }).then(usages => {
      this.lastRef = usages.length == this.limit ? usages[usages.length - 1]['__doc'] : null;
      this.usages = this.usages.concat(usages);
      this.docToTableConfig();
    })
  }

  protected onModeChange() {
    this.lastRef = null;
    this.usages = [];
    this.fetchUsages();
  }

  private docToTableConfig(): void {
    const config: IDataTableConfig = {
      data: this.usages.map((doc: UsageActivity) => {
        const r: ITableData = cloneDeep(doc);
        r.action = this.labels[UsageActionTypes[doc.action]];
        r.date = format(doc.timestamp, 'DD-MM-YYYY');
        r.role = this.labels[RoleTypes[doc.actionBy.role]];
        r.actionBy = `${doc.actionBy.firstName} ${doc.actionBy.lastName}`
        r._metadata = {
          originalData: doc
        }
        return r;
      }),
      displayProperties: ['action', 'actionBy', 'date', 'details'],
      rowOptions: [],
      allowSelection: false,
      headers: {
        action: this.labels.action,
        actionBy: this.labels.actionBy,
        date: this.labels.date,
        details: this.labels.details
      },
      displayHeaders: true,
    }
    this.isLoading = false;
    this.docTableConfig = config;
  }

  protected fetchMore() {
    this.fetchUsages();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
