import { DocumentLog } from './documentLog';

export enum SocialRelationKindTypes {
    father = 1,
    mother,
    sister,
    brother,
    son,
    daughter,
    family,
    friend,
    companion,
    mentor,
    other,
    girlfriend,
    neighbour,
    receiver,
    partner,
    niece,
    brotherInLaw,
    cousin,
    sisterInLaw,
    acquaintance,
    grandchild,
    parents
}

export class SocialRelation {
    socialRelationKind: SocialRelationKindTypes;
    note?: string;
    customerId: string;
    person: string;
    log?: DocumentLog;

    constructor(customerId: string) {
        this.customerId = customerId;
        this.note = '';
        this.socialRelationKind = null;
    }
}

export class NetworkInstitution {
    id?: string;
    customerId: string;
    institutionId: string;
    note?: string;
    log?: DocumentLog;

    constructor(customerId: string) {
        this.customerId = customerId;
        this.note = '';
    }
}

export class NetworkPartner {
    id?: string;
    customerId: string;
    partnerId: string;
    log?: DocumentLog;
    networkPartnerKind: string;
    note: string;

    constructor(customerId: string) {
        this.networkPartnerKind = null;
        this.customerId = customerId;
        this.note = '';
    }
}
