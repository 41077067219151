import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Sub } from '@shared/subscriptions';
import { User, UserConnectedServiceAndWorkingArea } from '@models/model';
import { cloneDeep, isEqual } from 'lodash';
import { ActivityService } from './activity.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { IObjectMap } from '@models/interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private sub = new Sub();
  private prevUser: User;
  public connectedWorkingAndServices = new BehaviorSubject<IObjectMap<UserConnectedServiceAndWorkingArea>>(null);

  constructor(
    private activityService: ActivityService,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) { }

  public init() {
    this.sub.add(
      this.localStorageService.getItem('user_role').subscribe(role => {
        if (role) this.getCurrentUser();
      })
)
  }

  private getCurrentUser() {
    this.sub.add(
      this.userService.getCurrentUser().subscribe(user => {
        if (!isEqual(this.prevUser?.roles, user.roles)) {
          this.activityService.getUserConnectedWorkingAreasAndServices().then(payload => {
            this.connectedWorkingAndServices.next(payload);
          });
          this.prevUser = cloneDeep(user);
        }
      })
    )
  }

  public unsubscribe() {
    this.sub.unsubscribe();
  }
}
