import { IObjectMap } from '@anchor-solutions-nl/translator-as';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CustomFormsService, FileUploadResponse, FileUploadService, LabelService, OrganizationService, UserPromptsService, UserService, WorkingAreaService } from '@core/services';
import { RoleTypes } from '@models/enum';
import { AttachedDoc, ICustomFormModel, ICustomFormModelData, IMembershipItem, IUserMembershipItem, Organization, Request, Role, User, WorkingArea } from '@models/model';
import { CustomFormComponent, DocInfoComponent } from '@shared/entry-components';
import { cloneDeep, filter, keyBy, keys, values } from 'lodash';
import { NgSub } from 'ng-sub';
import { take, takeUntil } from 'rxjs/operators';
import { IDataTableConfig, ITableData, RowOptions } from '../data-table/data-table.component';
import { DocumentsModelComponent } from '../documents-model/documents-model.component';
import { UserMembershipDialogComponent } from '../user-membership-dialog/user-membership-dialog.component';
import { Observable } from 'rxjs';
import { format } from 'date-fns';

@Component({
  selector: 'app-customer-tab',
  templateUrl: './customer-tab.component.html',
  styleUrls: ['./customer-tab.component.scss']
})
export class CustomerTabComponent implements OnInit, OnChanges, OnDestroy {

  @Input() customer: User;
  @Input() request: Request;

  @ViewChild('userImport', { static: true }) fileInput: ElementRef;
  public labels = this.labelService.defaultProvider();
  public role: Role;
  public customForms: ICustomFormModel[] = [];
  public currentServiceId: string;
  public currentAreaId: string;
  public docLoading = false;
  private sub = new NgSub();
  public userCustomForms: IObjectMap<ICustomFormModelData> = {};
  public customerDocs: AttachedDoc[] = [];
  public docTableConfig: IDataTableConfig;
  public progress = 0;
  public docCount: number;
  public org: Organization;
  protected isMobile: boolean;
  public tableCfg: IDataTableConfig;
  protected isUpdatingMembership = false;
  protected orgMemberships: IMembershipItem[];
  protected canCoordEdit = true;
  protected workingArea: WorkingArea;

  constructor(
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private fileUploadService: FileUploadService,
    private userService: UserService,
    private customFormsService: CustomFormsService,
    private orgnizationService: OrganizationService,
        private workingAreaService: WorkingAreaService
  ) { }

  ngOnInit(): void {
    this.sub.add(
      this.userPromptsService.activeMediaQuery$.subscribe(query => {
        this.isMobile = query === 'xs';
      })
    );
    this.labelService.getLabels$('app-customer-tab').subscribe(r => this.labels = r);
    this.role = this.userService.getCurrentUserRole();
    this.fetchCurrentWorkingArea();
    if (this.request) {
      this.currentAreaId = this.request.management.workareaId;
      this.currentServiceId = this.request.management.serviceId;
    } else {
      this.currentAreaId = this.role.workingAreaId;
      this.currentServiceId = this.role.serviceId;
    }
    this.initCustomForms();
    this.userService.getCounts('customerDoc', this.customer.id).then(data => this.docCount = data);

    this.userService.getUserDocs(this.customer.id, 3).pipe(takeUntil(this.sub)).subscribe((docs: AttachedDoc[]) => {
      this.customerDocs = docs;
      if (this.role.roleType === RoleTypes.professional) {
        this.customerDocs = filter(this.customerDocs, (doc: AttachedDoc) => {
          return doc.areadId[this.role.workingAreaId] === true;
        });
      }

      if (this.role.roleType === RoleTypes.coordinator) {
        this.customerDocs = filter(this.customerDocs, (doc: AttachedDoc) => {
          if (keys(doc.serviceId).length === 0 || this.customerDocs['undefined'] == true) {
            return doc.areadId[this.role.workingAreaId] === true;
          } else {
            return doc.serviceId[this.role.serviceId] === true;
          }
        });
      }

      // if (this.role.roleType === RoleTypes.excecutor) {
      //   this.customerDocs = filter(this.customerDocs, (doc: AttachedDoc) => {
      //     if (keys(doc.serviceId).length === 0) {
      //       return doc.areadId[this.request.management.workareaId] === true;
      //     } else {
      //       return doc.serviceId[this.request.management.serviceId] === true;
      //     }
      //   });
      // }
      this.docToTableConfig(this.customerDocs);
    });

    this.orgnizationService.getCurrentOrganization().subscribe(org => {
      this.org = org;
      this.orgMemberships = org.memberships?.filter(m => !this.customer.memberships?.[m.id] && m.active);
      this.toTableConfig(values(this.customer.memberships));
    })
  }

  private fetchCurrentWorkingArea() {
    if (this.role.roleType == RoleTypes.coordinator) {
      this.workingAreaService.getWorkingAreaById(this.role.workingAreaId).pipe(take(1)).toPromise()
        .then(workingArea => {
          this.workingArea = workingArea;
          this.canCoordEdit = this.workingArea.settings.coordinatorSettings.canEditUser;
        })
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.toTableConfig(values(this.customer.memberships));
  }

  private toTableConfig(memberships: IUserMembershipItem[]): void {
    const config: IDataTableConfig = {
      data: memberships.map((data: IUserMembershipItem) => {
        const r: ITableData = cloneDeep(data);
        r._metadata = {
          originalData: data,
        }
        r.title = data.membershipTitle;
        r.kind = data.membershipKind
        return r;
      }),
      displayProperties: ['title', 'kind'],
      rowOptions: [{
        icon: 'delete',
        id: 'remove',
        title: this.labels.delete
      }],
      allowSelection: false,
      displayHeaders: false
    }
    this.tableCfg = config;
  }

  public async membershipSelected(membership: IMembershipItem, userMembership?: IUserMembershipItem) {
    this.isUpdatingMembership = true;
    this.userPromptsService.showDialogue(UserMembershipDialogComponent, { userMembership, membership }, async (data: { from: Date, until?: Date, membershipKind: string, number?: string, active: boolean }) => {
      if (data) {
        this.customer.memberships = this.customer.memberships || {} as any
        this.customer.memberships[membership.id] = {
          membershipId: membership.id,
          membershipTitle: membership.title,
          memberFrom: format(data.from, 'YYYY-MM-DD'),
          membershipKind: data.membershipKind,
          memberUntil: data.until ? format(data.until, 'YYYY-MM-DD') : null,
          membershipNumber: data.number ? data.number : null,
          active: data.active
        }
        await this.userService.updateUser(this.customer, this.org.id);
      }
      this.isUpdatingMembership = false;
      this.orgMemberships = this.org.memberships.filter(m => !this.customer.memberships?.[m.id] && m.active);
    }, false, { width: '300px' });
  }

  public async handleRowAction(res: { option: RowOptions; data: any }) {
    const membership = res.data._metadata.originalData as IUserMembershipItem
    if (res.option.id == 'remove') {
      delete this.customer.memberships[membership.membershipId];
      await this.userService.updateUser(this.customer, this.org.id);
    }

    this.orgMemberships = this.org.memberships.filter(m => !this.customer.memberships?.[m.id] && m.active);
  }

  public openDoc(doc: ITableData) {
    window.open(doc._metadata.originalData.url, '_blank');
  }

  async showFileInfo(file: AttachedDoc) {
    if (!file) {
      return;
    }

    const fileMeta = await this.fileUploadService.getFileInfo(file.ref);

    this.userPromptsService.showDialogue(DocInfoComponent, {
      fileName: file.name,
      serviceName: this.role.service?.name || '',
      areaName: this.role.workingArea?.name || '',
      uploadedAt: fileMeta.timeCreated,
    });
  }

  public deleteFile(file: AttachedDoc) {
    this.userPromptsService.showConfirmDialogue(
      this.labels.delete_doc_message_title,
      this.labels.delete_doc_message_body,
      async confirm => {
        if (confirm) {
          try {
            this.fileUploadService.deleteFile(file.ref).catch();
            await this.userService.deleteDoc(file.id, this.customer.id);
            this.userPromptsService.showToast(this.labels.document_deleted);
          } catch (e) {
            this.userPromptsService.showToast(this.labels.cannot_delete_doc);
          }
        }
      }
    );
  }

  public selectFile() {
    this.fileInput.nativeElement.click();
  }

  public async onFileSelected(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    const isRightFormat = this.fileUploadService.fileMatchesFormat(target.files[0], [
      'csv',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'png',
      'jpg',
      'jpeg',
      'bpm',
      'pdf',
      'txt'
    ]);

    if (isRightFormat) {
      // here we upload the file to storage then we take the reference and save it to the user
      try {
        this.docLoading = true;
        this.sub.add(
          this.fileUploadService.uploadPercent.subscribe(percent => {
            this.progress = percent;
          }))
        const res: FileUploadResponse = await this.fileUploadService.uploadFile(
          target.files[0],
          this.customer.id
        );

        const doc = {
          name: res.fileName,
          ref: res.fileRef,
          url: res.fileUrl,
          size: this.fileUploadService.formatFileSize(res.metaData.size),
          type: res.metaData.contentType,
          isCustomerDoc: true,
          areadId: {
            [this.currentAreaId]: true
          },
          serviceId: {}
        };

        if (this.currentServiceId) {
          doc.serviceId[this.currentServiceId] = true;
        }

        await this.userService.addDoc(
          doc,
          this.customer.id
        );

        this.docLoading = false;
        this.userPromptsService.showToast(this.labels.document_added);
      } catch (e) {
        console.log(e);
        this.userPromptsService.showToast(this.labels.upload_failed);
        this.docLoading = false;
      }
    } else {
      this.userPromptsService.showToast(this.labels.wrong_format_uploaded);
    }
  }

  public async openCustomFormModal(customForm: ICustomFormModel) {
    this.userPromptsService.showDialogue(
      CustomFormComponent,
      {
        customForm: customForm,
        customerId: this.customer.id
      },
      () => {
        this.initCustomForms();
      }
    );
  }

  private initCustomForms() {
    let obs$: Observable<ICustomFormModel[]>;
    if (this.customer.id) {
      if (this.role.roleType == RoleTypes.excecutor) {
        obs$ = this.customFormsService.getOrganizationForms(this.role.roleType)
      } else {
        obs$ = this.customFormsService.getCustomFormsForManagement(
          this.currentAreaId, this.currentServiceId
        )
      }
      obs$.pipe(takeUntil(this.sub)).subscribe(async (customForms) => {
        this.customForms = customForms ? customForms.filter(i => !!i) : [];

        const userForms: ICustomFormModelData[] = await this.customFormsService
          .getUserCustomForms(this.customer.id)
          .pipe(take(1))
          .toPromise();
        this.userCustomForms = keyBy(userForms, 'formId');
      });
    }
  }

  public getCustomFormColor(form: ICustomFormModel): string {
    if (form && this.userCustomForms[form.id]) {
      return 'primary';
    }

    return 'default';
  }

  private docToTableConfig(docs: AttachedDoc[]): void {
    const config: IDataTableConfig = {
      data: docs.map((doc: any) => {
        const r: ITableData = cloneDeep(doc);
        r._metadata = {
          originalData: doc
        }
        return r;
      }),
      displayProperties: ['name'],
      rowOptions: [
        {
          id: 'info',
          title: this.labels.info,
          icon: 'info'
        },
        {
          id: 'remove',
          title: this.labels.delete,
          icon: 'delete'
        }
      ],
      allowSelection: false,
      displayHeaders: false,
    }
    this.docTableConfig = config;
  }

  public handleDocRowAction(res: { option: RowOptions; data: any }) {
    const data = res.data._metadata.originalData;
    if (res.option.id === 'info') this.showFileInfo(data);
    else this.deleteFile(data);
  }

  public openDocumentDialog() {
    this.userPromptsService.showDialogue(DocumentsModelComponent, { userId: this.customer.id, type: 'customer' }, null);
  }

  protected handleRowSelect(payload: ITableData) {
    const userMembership = payload._metadata.originalData as IUserMembershipItem;
    const membership: IMembershipItem = this.org.memberships.find(m => m.id == userMembership.membershipId);
    this.membershipSelected(membership, userMembership);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
