import { Injectable } from '@angular/core';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { UsageActivity, UsageActionTypes, User, UserActionBy, UserActionTo, Request, UsageActionKinds } from '@shared/model';
import { getYear, getMonth, getDate, getHours, getMinutes, subDays } from 'date-fns';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { IObjectMap } from '@shared/interface';
import { RoleTypes } from '@shared/enum';
import { take } from 'rxjs/operators';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';

@Injectable({
    providedIn: 'root',
})
export class UsageService {
    private orgId: string;

    constructor(
        private localStorageService: LocalStorageService,
        private afsDB: FirestoreService,
        private userService: UserService
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');
        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.orgId = orgId;
        });
    }

    private getDateComponents(date: Date) {
        return {
            year: getYear(date),
            month: getMonth(date),
            day: getDate(date),
            hour: getHours(date),
            minute: getMinutes(date),
            timestamp: date
        };
    }

    private getUsageRole(role: RoleTypes): RoleTypes {
        if (role === RoleTypes.coordinatorReservations) {
            return RoleTypes.coordinator;
        }

        return role;
    }

    public async addActivity(action: UsageActionTypes, affectedUser: IObjectMap<string>, request?: Request, details?:string): Promise<any> {
        const dates = this.getDateComponents(new Date());
        const userRole = JSON.parse(
            this.localStorageService.getItemSync('user_role')
        );

        const initiator: User = await this.userService.getCurrentUser().pipe(take(1)).toPromise();

        // get working area id from request. Pass in request as last arg instead of requestId
        let workingAreaId: string = userRole.workingArea ? userRole.workingArea.id : null;

        if (!workingAreaId && request) {
            workingAreaId = request.management.workareaId || null;
        }

        const actionByUser: UserActionBy = {
            userId: initiator.id,
            firstName: initiator.firstname,
            lastName: initiator.lastname,
            role: this.getUsageRole(userRole.roleType),
            workingAreaId: workingAreaId,
            orgId: this.orgId
        };

        const userActionTo: UserActionTo = {
            userId: affectedUser.userId,
            firstName: affectedUser.firstName,
            lastName: affectedUser.lastName
        };

        const activity: UsageActivity = {
            id: this.afsDB.getNewId(),
            action,
            year: dates.year,
            month: dates.month,
            day: dates.day,
            hour: dates.hour,
            minute: dates.minute,
            timestamp: dates.timestamp,
            actionBy: actionByUser,
            actionTo: userActionTo,
            details: details || '',
            usageActionKind: this.getUsageActionKind(action),
            requestId: request?.id || '',
        };

        if (userRole.service) {
            activity.actionBy.serviceId = userRole.service.id;
        }

        if (request) {
            activity.requestId = request.id;
        }  
        
        // create usage activity here
        const ref = `/organizations/${this.orgId}/users/${activity.actionBy.userId}/usage/${activity.id}`;
        return this.afsDB.setDoc(ref, activity as DocItem);
    }

    public getReportingForDate(month: number, year: number): Observable<UsageActivity[]> {
        return this.afsDB.colGroupWithIds$(`usage`, ref => {
            return ref.where('year', '==', year)
                .where('month', '==', month)
                .where('actionBy.orgId', '==', this.orgId);
        });
    }

    public getReportingForUserWithDateRange(userId: string, daysInterval: number, limit: number): Observable<UsageActivity[]> {
        const daysAgo: Date = subDays(new Date(), daysInterval);

        return this.afsDB.colGroupWithIds$(`usage`, ref => {
            return ref.where('actionBy.userId', '==', userId)
                .where('actionBy.orgId', '==', this.orgId)
                .where('timestamp', '>', daysAgo)
                .orderBy('timestamp', 'desc')
                .limit(limit);
        });
    }

    public getUsageCollectionGroup(payload: {userId: string, limit: number, mode?: UsageActionKinds, lastRef?: any, addDocRef?: boolean, requestId?: string}) {
        const where: Where[] = [['actionTo.userId', '==', payload.userId]];
        const opts: QueryConstraintOptions<UsageActivity> = { orderBy: [{ field: 'timestamp', val: 'desc' }], limit: payload.limit };
        return this.afsDB.colGroupWithIdsNew$<UsageActivity>('usage', () => {
            if (payload.mode) where.push(['usageActionKind', '==', payload.mode]);
            if (payload.requestId) where.push(['requestId', '==', payload.requestId]);
            if (payload.lastRef) {
                opts.startAfter = payload.lastRef;
            }
            return where;
        }, opts, payload.addDocRef).pipe(take(1)).toPromise();
    }
    
    private getUsageActionKind(usageActionTypes: UsageActionTypes) {
        switch (usageActionTypes) {
            case UsageActionTypes.view_request:
            case UsageActionTypes.view_user:
            case UsageActionTypes.view_institution: return UsageActionKinds.view;
            default: return UsageActionKinds.update;
        }
    }

}
