import { RequestActiveTimeline } from '.';
import { DocumentLog } from '../../interfaces';
import { IObjectMap } from '../interface';
import { ActivityKindTypes, UserActivityScopeType } from './activity';
import { IArea } from './postcodeAddress';
import { UnitTypes } from './product';
import { InstitutionInfoItem, RequestResult, RequestStoppedReason, UserInfoItem } from './request';

export type ReportingTypes = 'closedRequests' | 'newRequests' | 'openRequests' | 'activity' | 'createdRequests' | 'activeIn';
export type WayOfCreation = 'webapp' | 'csr' | 'repeat' | 'network';

export class ClosedRequestsRecord {
  id: string;
  requestId: string;
  titleRequest: string;
  startedAt: Date;
  closedAt: Date;
  closedMonth: number;
  result: RequestResult;
  closedWeek: number;
  closedYear: number;
  workingAreaId: string;
  serviceId: string;
  workingAreaName: string;
  serviceName: string;
  customerId: string;
  executorId: string;
  executorName: string;
  area: IArea;
  gender: string;
  age: number;
  log?: DocumentLog;
  requestStoppedReason: RequestStoppedReason;
  activeIn: RequestActiveTimeline;
  redirectedTo?: string;
  followupSteps?: string;
  requestKind?: string;
  closeRemark?: string;

  constructor() {
    this.executorId = '';
    this.customerId = '';
    this.executorName = '';
    this.id = '';
    this.requestId = '';
    this.titleRequest = '';
    this.startedAt = null;
    this.closedAt = null;
    this.closedMonth = null;
    this.closedWeek = null;
    this.closedYear = null;
    this.workingAreaId = '';
    this.serviceId = '';
    this.workingAreaName = '';
    this.serviceName = '';
    this.customerId = '';
    this.executorId = '';
    this.executorName = '';
    this.area = null;
    this.gender = '';
    this.age = null;
    this.requestStoppedReason = null;
    this.redirectedTo = '';
    this.followupSteps = '';
    this.requestKind = '';
    this.closeRemark = '';
  }
}

export class NewRequestsRecord {
  id: string;
  requestId: string;
  titleRequest: string;
  createdAt: Date;
  createdMonth: number;
  createdWeek: number;
  createdYear: number;
  workingAreaId: string;
  serviceId: string;
  workingAreaName: string;
  serviceName: string;
  customerId: string;
  orgId: string;
  area: IArea;
  gender: string;
  age: number;
  log?: DocumentLog;

  constructor() {
    this.customerId = '';
    this.id = '';
    this.requestId = '';
    this.titleRequest = '';
    this.createdAt = null;
    this.createdMonth = null;
    this.createdWeek = null;
    this.createdYear = null;
    this.workingAreaId = '';
    this.serviceId = '';
    this.workingAreaName = '';
    this.serviceName = '';
    this.customerId = '';
    this.orgId = '';
    this.area = null;
    this.gender = '';
    this.age = null;
  }
}

export class OpenRequestsRecord {
  request_title: string;
  customerName: string; // from management customer first + last name
  executorName: string; //from management executor first + last name
  status: string; // traslated
  createdAt: Date;
  workingarea: string;
  service: string;
  requestId: string;
  startDate: Date;
  endDate: Date;
  reference_number: string;
  productCode: string;
  requestKind: string;
  budget: number;
  totalMinutes: number;
  remaining: number;
  unitKind: UnitTypes;
  area?: IArea;
  referred_by: string;
  note: string;
  contactPerson?: UserInfoItem;
  institution?: InstitutionInfoItem;

  constructor() {
    this.referred_by = '';
    this.requestKind = '';
    this.request_title = '';
    this.note = '';
    this.startDate = null;
    this.endDate = null;
  }
}

export interface ClosedRequestsResult {
  completed: number;
  stopped: number;
  redirected: number;
  cancelled: number;
}

export interface NewRequestsAggregate {
  newRequests: number;
  uniqueCustomers: number;
  newCustomersInService: number;
  newCustomersInOrg: number;
}

export interface ClosedRequestsAggregate {
  closedRequests: number;
  resultRequest: ClosedRequestsResult;
  uniqueCustomers: number;
  uniqueExecutors: number;
  avgDuration: number;
}

export interface OpenRequestsAggregate {
  status: IObjectMap<string[]>;
}

export interface ReportingDoc<T> {
  id: string;
  workingAreaId: string;
  serviceId: string;
  year: number;
  reportingType: ReportingTypes;
  log?: DocumentLog;
  yearValues: T;
  monthValues: IObjectMap<T>; // month numbers map to objects
  weekValues: IObjectMap<T>;  // the week of the year's number map to objects
}

export class DashBoardReportStats {
  openRequests: {
    waitinglist: number;
    intake: number;
    assigned: number;
    inProgress: number;
    rejected: number;
  };
  timestamp: Date;
}

export class ActivityReportingRecord {
  id: string; // same as its activity id
  title: string;
  kind: ActivityKindTypes;
  date: Date;
  month: number;
  year: number;
  customerId?: string;
  executorId?: string;
  executorIds?: string[];
  userId: string;
  referenceNumber: string;
  minutes: number;
  budgetCode: string;
  workingAreaId: string;
  serviceId: string;
  workingAreaName: string;
  serviceName: string;
  requestId?: string;
  titleRequest?: string;
  amount: number;
  area?: IArea; // from customerId
  log?: DocumentLog;
  requestKind?: string;
  product?: string;
  description?: string;
  userActivityScope?: UserActivityScopeType;

  constructor() {
    this.executorId = '';
    this.customerId = '';
    this.id = '';
    this.requestId = '';
    this.titleRequest = '';
    this.date = null;
    this.month = null;
    this.year = null;
    this.workingAreaId = '';
    this.serviceId = '';
    this.workingAreaName = '';
    this.serviceName = '';
    this.customerId = '';
    this.executorId = '';
    this.userId = '';
    this.minutes = null;
    this.budgetCode = '';
    this.kind = null;
    this.title = '';
    this.area = null;
    this.amount = null;
    this.requestKind = '';
    this.product = '';
    this.description = '';

  }
}

interface CreatedAt {
  date: Date;
  month: number;
  week: number;
  year: number;
  quarter: number;
}

interface ClosedAt {
  date: Date;
  month: number;
  week: number;
  year: number;
  quarter: number;
}

export class RequestReportingRecord {
  id: string;
  requestId: string;
  titleRequest: string;
  workingAreaId: string;
  serviceId: string;
  workingAreaName: string;
  serviceName: string;
  customerId: string;
  orgId: string;
  customerArea: IArea;
  customerGender: string;
  executorGender: string
  customerBirthYear: number;
  log?: DocumentLog;
  startedAt: Date;
  result: string;
  executorId: string;
  requestStoppedReason: string;
  activeIn: RequestActiveTimeline;
  redirectedTo?: string;
  followupSteps?: string;
  requestKind?: string;
  closeRemark?: string;
  productCode?: string;
  referredBy?: string;
  customerGeo: {
    geoHash: string,
    latitude: number;
    longitude: number
  };
  executorGeo: {
    geoHash: string,
    latitude: number;
    longitude: number
  };
  status: string;
  createdAt: CreatedAt;
  closedAt: ClosedAt;
  budget: number;
  referenceNumber: string;
  executorBirthYear: string;
  executorArea: IArea
  contactPerson: UserInfoItem;
  insttution: InstitutionInfoItem;

  constructor() {
    this.customerId = '';
    this.id = '';
    this.requestId = '';
    this.titleRequest = '';
    this.createdAt = null;
    this.closedAt = null
    this.workingAreaId = '';
    this.serviceId = '';
    this.workingAreaName = '';
    this.serviceName = '';
    this.orgId = '';
    this.customerArea = null;
    this.executorArea = null
    this.customerGender = '';
    this.executorGender = '';
    this.customerBirthYear = null;
    this.executorBirthYear = null;
    this.startedAt = null;
    this.executorId = '';
    this.requestStoppedReason = null;
    this.redirectedTo = '';
    this.followupSteps = '';
    this.requestKind = '';
    this.closeRemark = '';
    this.productCode = '';
    this.referredBy = '';
    this.customerGeo = null;
    this.executorGeo = null;
    this.createdAt = null;
    this.closedAt = null;
    this.budget = null;
    this.referenceNumber = '';
    this.executorBirthYear = null;
    this.executorArea = null;
    this.contactPerson = null;
    this.insttution = null;
  }
}

export class MembershipReportingRecord {
  id?: string;
  membershipId: string;
  title: string;
  userId: string;
  orgId: string;
  kind: string;
  membershipFrom: string;
  membershipUntil: string;
  area: IArea; //from user
  activeIn: RequestActiveTimeline;
  month: number;
  year: number;
  log?: DocumentLog;
  birthday: string
}