import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { IMembershipItem, IUserMembershipItem } from '@models/model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-user-membership-dialog',
  templateUrl: './user-membership-dialog.component.html',
  styleUrl: './user-membership-dialog.component.scss'
})
export class UserMembershipDialogComponent implements OnInit {

  public labels = this.labelService.defaultProvider();
  protected form: FormGroup;
  private userMembership: IUserMembershipItem;
  protected memberships: string[];
  
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserMembershipDialogComponent>,
    private labelService: LabelService,
    @Inject(MAT_DIALOG_DATA) private data: { userMembership: IUserMembershipItem, membership: IMembershipItem }
  ) { }

  async ngOnInit(): Promise<void> {
    this.userMembership = cloneDeep(this.data.userMembership);
    this.memberships = cloneDeep(this.data.membership).kind;
    this.labels = (await this.labelService.getLabels('app-user-membership-dialog')).data
    this.setupForm();
  }

  private setupForm() {
    this.form = this.fb.group({
      from: [this.userMembership?.memberFrom || new Date(), Validators.required],
      until: [this.userMembership?.memberUntil],
      membershipKind: [this.userMembership?.membershipKind],
      number: [this.userMembership?.membershipNumber],
      active: [this.userMembership?.active == undefined ? true : this.userMembership?.active]
    })
  }

  protected save() {
    this.dialogRef.close(this.form.value);
  }
}
