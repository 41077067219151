<app-modal [alignFooterStart]="true" [hideCloseButton]="true">
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
    <span class="modal_title">{{labels.create_new_user}}</span>
  </div>

  <div class="modal_body" #modal_body>
    <div class="content">
      <form class="user-edit-form" [formGroup]="form">
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.firstname}}</mat-label>
            <input matInput (focusout)="checkUserDuplicatesByNameOrEmail()" required minlength="2"
              formControlName="firstname">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{labels.lastname}}</mat-label>
            <input matInput required (focusout)="checkUserDuplicatesByNameOrEmail()" formControlName="lastname"
              minlength="2">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline" class="small">
            <mat-label>{{labels.gender}}</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="male" selected>{{labels.male}}</mat-option>
              <mat-option value="female">{{labels.female}}</mat-option>
              <mat-option value="unknown">{{labels.unkown}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{labels.birthday}}</mat-label>
            <input type="date" formControlName="birthday" matInput>
          </mat-form-field>

          <!-- The extra space should be filled here -->
        </div>

        <div>
          <app-address-modal-opener [currentAddress]="data.user.address" (address)="checkUserDuplicatesByAddress($event)" />
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.email}}</mat-label>
            <input matInput (focusout)="checkUserDuplicatesByNameOrEmail(true)" formControlName="email" type="email">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{labels.email2}}</mat-label>
            <input matInput formControlName="email2" type="email">
          </mat-form-field>
        </div>

        <div class="form-row">

          <app-phone-input [value]="form.value.phone" (update)="updatePhoneField(form, 'phone', $event)">
          </app-phone-input>

          <mat-form-field appearance="outline">
            <mat-label>{{labels.remark_phone}}</mat-label>
            <input matInput tabindex="-1" formControlName="phoneRemark">
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>{{labels.user_short_note}}</mat-label>
            <textarea matInput maxlength="130" cdkTextareaAutosize minRows="2" #input
              formControlName="usernote"></textarea>
            <mat-hint align="end">{{input.value.length}}/130</mat-hint>
          </mat-form-field>
        </div>

        <div class="form-row" [style]="{width: org?.settings?.showUserPersonalNumber && mailinglists?.length ? '100%' : '50%'}">
          <mat-form-field *ngIf="org?.settings?.showUserPersonalNumber" appearance="outline" class="full-width">
            <mat-label>{{labels.personal_number}}</mat-label>
            <input autocomplete="off" matInput type="text" formControlName="personalNumber">

            <mat-error *ngIf="form.get('personalNumber').errors?.wrongNumber">
              {{form.get('personalNumber')?.errors?.wrongNumber}}
            </mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="mailinglists?.length" appearance="outline" class="full-width">
            <mat-label>{{labels.mailinglists}}</mat-label>
            <mat-select multiple formControlName="mailinglistSubscriptions">
              <mat-option *ngFor="let m of mailinglists" [value]="m.id"
                [disabled]="!m.userCanSubscribe || !loggedInUser?.isOrgAdmin">
                {{m.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-slide-toggle color="primary" formControlName="termsAccepted">
          {{labels.terms_accepted}}
        </mat-slide-toggle>
      </form>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxFlex="stretch" fxLayoutGap="10px" fxLayoutAlign="flex-start"> </div>

    <span>
      <button mat-button (click)="closeDialog()">{{labels.cancel}}</button>
    </span>
    <span>
      <button mat-raised-button color="primary" [disabled]="!form.valid || loading" (click)="saveUser()">
        {{labels.save}}
      </button>
    </span>
  </div>
</app-modal>