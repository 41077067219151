import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, UserService } from '@core/services';
import { User } from '@models/model';
import { Sub } from '@shared/subscriptions';

@Component({
  selector: 'app-user-last-seen-dialog',
  templateUrl: './user-last-seen-dialog.component.html',
  styleUrl: './user-last-seen-dialog.component.scss'
})
export class UserLastSeenDialogComponent implements OnInit, OnDestroy {

  protected isLoading = true;
  private sub = new Sub();
  protected users: User[];
  public labels: any = {};

  constructor(
    private dialogRef: MatDialogRef<UserLastSeenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { user: User },
    private userService: UserService,
    private labelService: LabelService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-user-last-seen-dialog')).data;
    this.loadUsersLastSeen();
  }

  private loadUsersLastSeen() {
    this.sub.add(
      this.userService.getUsersFromIds(this.data.user.usersLastSeen).subscribe(users => {
        this.users = users.filter(u => !!u);
        this.isLoading = false;
      })
    )
  }

  protected gotoUser(user: User) {
    this.dialogRef.close(user);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
