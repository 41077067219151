<app-modal>
    <div class="modal_header" #modal_header>
        <div class="modal_title">{{user?.fullname}}</div>
        <div class="modal_subtitle">{{labels.requests}}</div>
    </div>

    <div class="modal_body m-2" #modal_body>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        <div>
            @if (tableCfg?.data.length) {
            <data-table [configOptions]="tableCfg">
            </data-table>
            }
        </div>
    </div>
</app-modal>