<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.planned_leaves}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 1em;">
        <mat-tab-group>
            <mat-tab [label]="labels.list">
                <ng-template matTabContent>
                    <app-planned-leave-users-list [plannedLeaves]="plannedLeaves"/>
                </ng-template>
            </mat-tab>
            <mat-tab [label]="labels.chart">
                <ng-template matTabContent>
                    <app-planned-leave-user-chart [plannedLeaves]="plannedLeaves"/>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </div>
</app-modal>