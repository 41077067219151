<app-modal [alignFooterStart]="true">
    <div class="modal_header" #modal_header>
        <div class="modal_title">{{user?.fullname}}</div>
        <div class="modal_subtitle">{{labels.service_offering}} - {{service.name}}</div>

    </div>

    <div class="modal_body m-2" #modal_body>
        @if (form) {
        <form [formGroup]="form">
            <mat-form-field class="full-width">
                <!-- <mat-label>{{labels.service_offering}}</mat-label> -->
                <span>{{labels.service_offering}}</span>
                <mat-chip-grid #chipGrid aria-label="Offerings selection">
                    @for (offerings of executorOfferings(); track $index) {
                    <mat-chip-row (removed)="remove(offerings)">
                        {{offerings}}
                        <button matChipRemove [attr.aria-label]="'remove ' + offerings">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    }
                </mat-chip-grid>
                <input name="currentOfferings" [placeholder]="labels.add" #fruitInput formControlName="serviceOffering"
                    [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    @for (offering of filteredOfferings(); track offering) {
                    <mat-option [value]="offering">{{labels[offering]}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="full-width">
                <!-- <mat-label>{{labels.employability}}</mat-label> -->
                <span>{{labels.employability}}</span>
                <mat-chip-grid #chipGridEmployAbility aria-label="Offerings selection">
                    @for (ability of employability(); track $index) {
                    <mat-chip-row (removed)="removeEmployability(ability)">
                        {{ability}}
                        <button matChipRemove [attr.aria-label]="'remove ' + ability">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    }
                </mat-chip-grid>
                <input name="currentAbility" [placeholder]="labels.add" #abilityInput formControlName="employability"
                    [matChipInputFor]="chipGridEmployAbility" [matAutocomplete]="autoEmployAbility"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addEmployability($event)" />
                <mat-autocomplete #autoEmployAbility="matAutocomplete" (optionSelected)="selectedEmployability($event)">
                    @for (ability of filteredAbilities(); track ability) {
                    <mat-option [value]="ability">{{labels[ability]}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.description}}</mat-label>
                <textarea matInput formControlName="remark" cdkTextareaAutosize cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="5" [maxlength]="maxDescriptionLen">
                                            </textarea>
            </mat-form-field>
        </form>
        }

    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px" fxLayout="row"  fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="save()">{{labels.save}}</button>
    </div>
</app-modal>
