import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, UserPromptsService, UserService } from '@core/services';
import { IObjectMap } from '@models/interface';
import { DailyAvailabilitySession, defaultUserAvailability, PlannedLeave, User, UserAvailabilityTypes, UserDailyAvailability } from '@models/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { cloneDeep, isEqual, keys, orderBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { PlannedLeaveComponent } from '../planned-leave/planned-leave.component';
import { format, getYear } from 'date-fns';
import { IDataTableConfig, ITableData } from '@shared/components';

@Component({
  selector: 'app-user-availability-modal',
  templateUrl: './user-availability-modal.component.html',
  styleUrls: ['./user-availability-modal.component.scss']
})
export class UserAvailabilityModalComponent extends ModalComponent implements OnInit, OnDestroy {

  public labels = this.labelService.defaultProvider();
  public user: User;
  public weekdays: { id: number; name: string; prop: string; }[] = [];
  public availabilityDays: IObjectMap<string[]> = {};
  public availabilitySessions = ['morning', 'afternoon', 'evening', 'night'];
  public availabilityStatus: { status: string, id: number }[] = [];
  private sub = new NgSub();
  protected UserAvailabilityTypes = UserAvailabilityTypes;
  protected selectedWeekdays: { id: number; name: string; prop: string; }[] = [];
  protected tableCfg: IDataTableConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<UserAvailabilityModalComponent>,
    private labelService: LabelService,
    private userService: UserService,
    private userPromptsService: UserPromptsService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.user = this.data.user;
    this.dialogRef.disableClose = true;
    this.labelService.getWeekdayList().then(w => {
      // changes order so that monday is first and sunday is last
      this.weekdays = w.slice(1).concat([w[0]]);
      this.weekdays.forEach(w => {
        if (this.user.availability.days[w.prop].available) {
          this.selectedWeekdays.push(w);
        }
      })
    });

    this.labels = (await this.labelService.getLabels('app-user-availability-modal')).data;

    if (!this.user.availability) {
      this.user.availability = defaultUserAvailability();
    }

    this.prepareData();

    for (const status in UserAvailabilityTypes) {
      if (isNaN(Number(status))) {
        this.availabilityStatus.push({ status: this.labels[status], id: parseInt(UserAvailabilityTypes[status]) });
      }
    }

    this.toTableConfig()
  }

  private prepareData(): void {
    this.availabilityDays = {};
    keys(this.user.availability.days).forEach(day => {
      const val = this.user.availability.days[day] as UserDailyAvailability;

      this.availabilityDays[day] = this.availabilitySessions.map(s => {
        return (val[s] as DailyAvailabilitySession).available ? s : null;
      }).filter(f => f);
    });
  }

  public async saveAvailability() {
    this.weekdays.forEach((week) => {
      const selectedWeekday = this.selectedWeekdays.find(sw => sw.prop == week.prop);
      if (selectedWeekday) {
        const item = this.user.availability.days[selectedWeekday.prop] as UserDailyAvailability;

        // ensures that availability is always false when no period is selected
        if (this.availabilityDays[selectedWeekday.prop].length === 0) {
          item.available = false;
        }

        // updates user availability from model
        this.availabilitySessions.forEach(session => {
          // ensures that all sessions are always false when availability is false
          item[session].available = item.available ? this.availabilityDays[selectedWeekday.prop].indexOf(session) >= 0 : false;
        });
      } else {
        const item = this.user.availability.days[week.prop] as UserDailyAvailability;
        item.available = false;
        this.availabilitySessions.forEach(session => {
          // ensures that all sessions are always false when availability is false
          item[session].available = false
        });
      }
    })
    if (this.user.availability.availability === UserAvailabilityTypes.available) {
      this.user.availability.commentNotAvailable = '';
    }

    await this.userService.updateUser(this.user);
    this.userPromptsService.showToast(this.labels.availability_updated);
    this.dialogRef.close(this.user);
  }

  protected weekdaySelected() {
    this.selectedWeekdays.forEach(week => {
      this.user.availability.days[week.prop].available = true;
      if (!this.availabilityDays[week.prop].length) {
        this.availabilityDays[week.prop] = ['morning', 'afternoon'];        
      }
    })
  }

  private toTableConfig(): void {
    const config: IDataTableConfig = {
      data: orderBy(this.user.availability.plannedLeave || [], 'from').map(data => {
        return {
          _metadata: {
            originalData: cloneDeep(data)
          },
          from: format(data.from, 'DD-MM-YYYY'),
          to: format(data.to, 'DD-MM-YYYY'),
          remark: data.remark
        };
      }),
      displayProperties: ['from', 'to', 'remark'],
      rowOptions: [
        {
          id: 'edit',
          title: this.labels.edit,
          icon: 'edit'
        },
        {
          id: 'remove',
          title: this.labels.delete,
          icon: 'delete'
        }
      ],
      allowSelection: false,
      displayHeaders: false,
    }
    this.tableCfg = config;
  }

  public handleRowSelect(res: ITableData): void {
    this.editPlannedLeave(res._metadata.originalData as PlannedLeave);
  }

  public async handleRowAction(res: ITableData) {
    const plannedLeave = res.data._metadata.originalData as PlannedLeave
    if (res.option.id === 'edit') {
      this.editPlannedLeave(plannedLeave);
    } else {
      const index = this.user.availability.plannedLeave.findIndex(pl => isEqual(pl, plannedLeave));
      if (index >= 0) {
        this.user.availability.plannedLeave.splice(index, 1);
        await this.userService.updateUser(this.user);
        this.userPromptsService.showToast(this.labels.availability_updated);
      }
    }
    this.toTableConfig();
  }

  protected addPlannedLeave() {
    this.openPlannedLeaveDialog({} as any, async (updatedLeave) => {
      this.user.availability.plannedLeave = this.user.availability.plannedLeave || [];
      this.user.availability.plannedLeave.push(updatedLeave);
      await this.userService.updateUser(this.user);
      this.userPromptsService.showToast(this.labels.availability_updated);
    });
  }

  protected editPlannedLeave(plannedLeave: PlannedLeave) {
    this.openPlannedLeaveDialog(plannedLeave, async (updatedLeave) => {
      const userPlannedLeave = this.user.availability.plannedLeave;
      const index = userPlannedLeave.findIndex(pl => isEqual(pl, plannedLeave));
      userPlannedLeave[index] = updatedLeave;
      await this.userService.updateUser(this.user);
      this.userPromptsService.showToast(this.labels.availability_updated);
    });
  }

  protected openPlannedLeaveDialog(plannedLeave: PlannedLeave, cb: (pl: PlannedLeave) => void) {
    const userPlannedLeave = this.user.availability.plannedLeave;
    this.userPromptsService.showDialogue(PlannedLeaveComponent, { plannedLeave: cloneDeep(plannedLeave) }, async (updatedPlannedLeave: PlannedLeave) => {
      if (updatedPlannedLeave) {
        const currentYear = getYear(new Date());
        const index = userPlannedLeave?.findIndex(pl => (getYear(pl.to) + 1) < currentYear);
        if (index >= 0) userPlannedLeave.splice(index, 1);
        cb(updatedPlannedLeave);
        this.toTableConfig();
      }
    }, false, { width: '600px' });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
