<div fxLayout="row" fxLayoutGap="15px">
    @if (form?.pristine && showMoreConfig?.inUse) {
    <button mat-button color="primary" style="width: max-content;"
        (click)="onShowMore.emit(!showMoreConfig?.showMore)">{{showMoreConfig?.showMore ? labels.show_less :
        labels.show_more}}</button>
    }
    @if (form?.dirty) { <button mat-button color="primary" (click)="discard.emit()">{{labels.cancel}}</button>}
    @if (!hideSaveBtn) {
    <button mat-raised-button color="primary" [disabled]="form?.pristine" (click)="save.emit()">{{labels.save}}</button>
    }
</div>