import { Component, Input, OnInit, output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { LabelService } from '@core/services';

@Component({
  selector: 'app-card-action-button',
  templateUrl: './card-action-button.component.html',
  styleUrl: './card-action-button.component.scss'
})
export class CardActionButtonComponent implements OnInit {

  @Input({ required: true }) form: AbstractControl<any, any>;
  @Input() showMoreConfig: {showMore: boolean, inUse: boolean};
  @Input() hideSaveBtn: boolean;

  public discard = output();
  public save = output();
  public onShowMore = output<boolean>();
  
  public labels = this.labelService.defaultProvider();

  constructor(private labelService: LabelService) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-card-action-button')).data;
  }
}
