<div fxLayout="row" fxLayoutGap="5px">
  <mat-form-field appearance="outline" fxFlex="120px">
    <mat-label>{{labels.country_code}}</mat-label>
    <mat-select [ngClass]="{readonly: readonly}" [(ngModel)]="countryCode" tabindex="-1"
       (ngModelChange)="onInputChange()" [disabled]="readonly">
      <mat-select-trigger>
        {{countryCode}}
      </mat-select-trigger>
      <mat-option *ngFor="let code of countryCodes" [value]="code.code">
        {{ code.name }} ({{code.code}})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="stretch">
    <mat-label>{{placeholder || labels.phone_number}}</mat-label>
    <input [readonly]="readonly" matInput [(ngModel)]="phone"
      (ngModelChange)="onInputChange()">
    <mat-hint *ngIf="!isValidPhone()" align="end" class="text-danger">{{labels.invalid_phone}}</mat-hint>
    @if (hint && !hint?.hide) {
      <mat-hint>{{hint.text}}</mat-hint>
    }
  </mat-form-field>
</div>