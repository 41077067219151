import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPromptsService, UtilitiesService } from '@core/services';
import { CustomAreaService } from '@core/services/custom-area.service';
import { LabelService, WeekDayItem } from '@core/services/labels.service';
import { IObjectMap } from '@models/interface';
import { CustomArea, IAreaItem, PlannedLeave, Service, User, UserAvailabilityTypes, UserDailyAvailability } from '@models/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ExecutorOfferingsComponent } from '@users/components/executor-offerings/executor-offerings.component';
import { UserAvailabilityModalComponent } from '@users/components/user-availability-modal/user-availability-modal.component';
import { isAfter, isBefore } from 'date-fns';
import { cloneDeep, isNaN, orderBy, uniqBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { ExecutorRequestDialogComponent } from '../executor-request-dialog/executor-request-dialog.component';

interface availabilityFilter {
  searchText?: string;
  status?: boolean;
  gender?: string;
  customArea?: string;
  district?: string
}

@Component({
  selector: 'app-availability-selector',
  templateUrl: './availability-selector.component.html',
  styleUrls: ['./availability-selector.component.scss']
})
export class AvailabilitySelectorComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public selected: User;
  public users: User[] = [];
  public weekdays: WeekDayItem[] = [];
  public availabilitySessions: string[] = [];
  public selectedWeekday: string;
  public textShortener: IObjectMap<boolean> = {};
  public field: string;
  private sub = new NgSub();
  private periodSessions = ['morning', 'afternoon', 'evening', 'night'];
  public executorsStatus: IObjectMap<{ status: string; count: number }[]>;
  private service: Service;
  protected availabilityFilter: availabilityFilter = { status: true };
  protected districts: IAreaItem[];
  protected customAreas: CustomArea[];
  private timeout: NodeJS.Timeout;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { users: User[]; selected: User; customer?: User; field: string, service: Service },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<AvailabilitySelectorComponent>,
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
    private customAreaService: CustomAreaService,
    private userPromptsService: UserPromptsService
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.selected = this.data.selected;
    this.service = cloneDeep(this.data.service);
    this.labelService.getWeekdayList().then(w => {
      // changes order so that monday is first and sunday is last
      this.weekdays = w.slice(1).concat([w[0]]);

      // const weekday = getDay(new Date());
      this.selectWeekday();
    });

    this.labelService.getLabels('app-availability-selector').then(r => this.labels = r.data);
    this.customAreas = await this.customAreaService.getCustomAreasNocache();
    this.field = this.data.field;
    if (!this.customAreas.length) this.districts = uniqBy(this.data.users.filter(u => !!u.area).map(u => u.area.district), 'code');
  }

  public hoverEffect(id: string, action: boolean) {
    if (id === this.selected?.id) {
      return;
    }

    const elem = document.getElementById(id);

    if (action) {
      elem.classList.add('border-primary');
    } else {
      elem.classList.remove('border-primary');
    }
  }

  public selectWeekday(day?: WeekDayItem): void {
    this.selectedWeekday = day?.prop;
    this.filterUsers();

    if (this.selectedWeekday) {
      this.availabilitySessions = cloneDeep(this.periodSessions);
    } else {
      this.availabilitySessions = this.weekdays.map(w => w.prop);
    }
  }

  public userSubtitle(user: User): string {
    let s = user.area?.neighbourhood?.text || '';

    if (this.data.customer?.address?.geo && user.address?.geo) {
      const dst = this.utilitiesService.distance(
        user.address.geo['longitude'],
        user.address.geo['latitude'],
        this.data.customer.address.geo['longitude'],
        this.data.customer.address.geo['latitude'],
        'K'
      );

      if (!isNaN(dst)) {
        if (s.length > 0) {
          s += ' - ';
        }

        s += dst + 'km';
      }
    }

    return s;
  }

  public commentText(user: User): string {

    let text: string = '';

    if (text.length > 0) {
      text += '\n';
    }

    if (user.availability.availability && user.availability.availability === UserAvailabilityTypes.notAvailable) {
      if (user.availability?.commentNotAvailable && user.availability?.commentNotAvailable.length > 0) {
        text += user.availability?.commentNotAvailable;
        text += '\n';
      }
    } else {
      if (user.availability?.comment.length > 0) {
        text += user.availability?.comment;
        text += '\n';
      }
    }


    const offerings: string[] = user.roles.services[this.service.id].serviceOffering;
    const employability: string[] = user.roles.services[this.service.id].employability as string[];
    const remark = user.roles.services[this.service.id].remark;


    if (employability && employability.length > 0) {
      text += employability.join(', ');
      text += '\n';
    }

    if (offerings && offerings.length > 0) {
      text += offerings.join(', ');
      text += '\n';
    }

    if (remark && remark.length > 0) {
      text += remark;
      text += '\n';
    }

    if (user.employeeDetails?.offers) {
      if (user.employeeDetails?.offers.length > 0) {
        text += user.employeeDetails?.offers || '';
        text += '\n';
      }
    }

    return text;
  }

  public onFilterUsers(): void {
    this.filterUsers();
  }

  protected onSearchUsers() {
    if (this.availabilityFilter.searchText?.length >= 3) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.filterUsers();
      }, 1000);
    }
    if (!this.availabilityFilter.searchText)this.filterUsers();
  }

  public isActive(item: IObjectMap<UserDailyAvailability>, session: string): boolean {
    if (!item) {
      return false;
    }

    if (!this.selectedWeekday) {
      return item[session].available;
    }

    const s = item[this.selectedWeekday]?.[session];
    return s?.available;
  }

  private filterUsers(): void {
    let filters = this.getActiveFilters();
    const users = (this.data.users || []).filter(u => {
      let counter = 0;

      if (!u.availability && this.selectedWeekday) {
        return false;
      }

      const item = this.selectedWeekday ? u.availability.days[this.selectedWeekday] as UserDailyAvailability : null;
      if (this.selectedWeekday && !item?.available) {
        return false;
      }

      if (this.availabilityFilter.searchText?.length >= 3) {
        const user = cloneDeep(u);
        delete user.availability;
        if (JSON.stringify(user).toLowerCase().includes(this.availabilityFilter.searchText.toLowerCase())) counter++;
      }
      if (this.data.field === 'executor') {
        if (u.roles.services[this.service.id].executor.disabled == !this.availabilityFilter.status) counter++;
      }
      if (this.availabilityFilter.gender && u.gender == this.availabilityFilter.gender) counter++;
      if (this.availabilityFilter.district && u.area?.district?.code == this.availabilityFilter.district) counter++;
      if (this.availabilityFilter.customArea && u.customAreaId == this.availabilityFilter.customArea) counter++;
      return counter == filters ? true : false;
    });
    this.users = orderBy(users, 'lastname');
    this.mapExecutors();
  }

  private getActiveFilters() {
    return Object.keys(this.availabilityFilter).filter(key => {
      if (this.availabilityFilter.customArea && this.availabilityFilter[key]) return true;
      if (this.availabilityFilter.district && this.availabilityFilter[key]) return true;
      if (this.availabilityFilter.gender && this.availabilityFilter[key]) return true;
      if (this.availabilityFilter.searchText && this.availabilityFilter[key]) return true;
      if (this.data.field === 'executor') {
        if (key == 'status') return true;
      }
    }).length;
  }

  private mapExecutors() {
    const obj = {};
    this.users.forEach(user => {
      obj[user.id] = [];
      if (user?.executorDetails?.requestsCounts) {
        for (const key in user.executorDetails.requestsCounts) {
          obj[user.id].push({
            status: key,
            count: user.executorDetails.requestsCounts[key]
          })
        }
      }
    });
    this.executorsStatus = obj;
  }

  public userSelected(user: User): void {
    this.selected = user;
    this.dialogRef.close(user);
  }

  protected getPlannedLeave(plannedLeaves: PlannedLeave[]) {
    if (plannedLeaves) {
      return plannedLeaves.filter(plannedLeave => isBefore(new Date(), plannedLeave.to))
    }
    return [];
  }

  protected hasOnGoingPlannedLeave(plannedLeaves: PlannedLeave[]) {
    const now = new Date();
    return !!(plannedLeaves || []).find(plannedLeave => isBefore(now, plannedLeave.to) && isAfter(now, plannedLeave.from));
  }

    protected openAvailabilityDialog(user: User): void {
    this.userPromptsService.showDialogue(UserAvailabilityModalComponent, {
      user,
    });
  }

  protected openExecutorOfferingsDialog(user: User) {
    this.userPromptsService.showDialogue(ExecutorOfferingsComponent, { user, service: this.service }, null, true);
  }

  protected openExecutorRequests(user: User, exeStatus: { status: string; count: number }) {
    this.userPromptsService.showDialogue(ExecutorRequestDialogComponent, { user, exeStatus: +exeStatus.status });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
