import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { PlannedLeave, User } from '@models/model';
import { isAfter, isEqual } from 'date-fns';
import { cloneDeep, flatten, orderBy } from 'lodash';

export interface IUserPlannedLeave {
  fullName: string;
  start: Date;
  end: Date;
  remark: string;
}

export interface ExtendedPlannedLeave extends PlannedLeave {
  userName: string;
}

@Component({
  selector: 'app-planned-leave-users-dialog',
  templateUrl: './planned-leave-users-dialog.component.html',
  styleUrl: './planned-leave-users-dialog.component.scss'
})
export class PlannedLeaveUsersDialogComponent implements OnInit {

  public labels: ILabelsProvider = this.labelService.defaultProvider();
  protected plannedLeaves: ExtendedPlannedLeave[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { users: User[] },
    private labelService: LabelService,
  ) { }

  async ngOnInit(): Promise<void> {
    const now = new Date();
    const mainUsers = cloneDeep(this.data.users).filter(u => u.availability.plannedLeave?.length);
    const filteredPlannedLeave = flatten(mainUsers.map(u => {
      return u.availability.plannedLeave.filter(p => isEqual(p.to, now) || isAfter(p.to, now)).map(pl => {
        return {
          ...pl,
          userName: u.firstname + ' ' + u.lastname
        }
      }) as ExtendedPlannedLeave[]
    }));

    this.plannedLeaves = orderBy(filteredPlannedLeave, 'from', 'asc');
    this.labels = (await this.labelService.getLabels('app-planned-leave-users-dialog')).data;
  }
}
