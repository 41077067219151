import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { FirestoreService } from './firestore.service';
import { CustomArea, CustomAreaPostalCodes } from '@models/model';

@Injectable({
  providedIn: 'root'
})
export class CustomAreaService {

  private orgId: string;
  private colRef: string;

  constructor(
    private localStorageService: LocalStorageService,
    private afs: FirestoreService
  ) {
    this.localStorageService.getItem('user_organization').subscribe(orgId => {
      this.orgId = orgId;
      this.colRef = `/organizations/${this.orgId}/customAreas`;
    });
  }

  public createCustomArea(customArea: CustomArea) {
    customArea.id = this.afs.getNewId();
    return this.afs.setDoc(`${this.colRef}/${customArea.id}`, customArea);
  }

  public getCustomAreas() {
    return this.afs.colWithIdsNew$<CustomArea>(this.colRef);
  }

  public getCustomAreasNocache() {
    return this.afs.ColWithIdsNoCacheNew<CustomArea>(this.colRef);
  }

  public updateCustomArea(customArea: CustomArea) {
    return this.afs.update(`${this.colRef}/${customArea.id}`, customArea);
  }

  public deleteCustomArea(id: string) {
    return this.afs.remove(`${this.colRef}/${id}`);
  }

  public getCustomAreasPostalCodes(customAreId: string) {
    return this.afs.colWithIdsNew$<CustomAreaPostalCodes>(`${this.colRef}/${customAreId}/customAreaPostalCodes`);
  }

  public createCustomAreasPostalCode(customAreaId: string, postalCode: CustomAreaPostalCodes) {
    postalCode.id = this.afs.getNewId();
    return this.afs.setDoc(`${this.colRef}/${customAreaId}/customAreaPostalCodes/${postalCode.id}`, postalCode);
  }

  public deleteCustomAreasPostalCode(customAreaId: string, customAreaPostalCodeId: string) {
    return this.afs.remove(`${this.colRef}/${customAreaId}/customAreaPostalCodes/${customAreaPostalCodeId}`);
  }

  public getCustomAreasPostalCodesNocache(customAreId: string) {
    return this.afs.ColWithIdsNoCacheNew<CustomAreaPostalCodes>(`${this.colRef}/${customAreId}/customAreaPostalCodes`);
  }
}
