import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, RequestService } from '@core/services';
import { RequestStatus } from '@models/enum';
import { Request, Service, User } from '@models/model';
import { IDataTableConfig, ITableData } from '@shared/components';
import { Sub } from '@shared/subscriptions';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-executor-request-dialog',
  templateUrl: './executor-request-dialog.component.html',
  styleUrl: './executor-request-dialog.component.scss'
})
export class ExecutorRequestDialogComponent implements OnInit, OnDestroy {

  public labels = this.labelService.defaultProvider();
  public user: User;
  private sub = new Sub();
  public tableCfg: IDataTableConfig;
  public isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { user: User, service: Service, exeStatus: RequestStatus },
    private requestService: RequestService,
    private labelService: LabelService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = cloneDeep(this.data.user);
    this.labels = (await this.labelService.getLabels('app-executor-request-dialog')).data;
    this.fetchRequests();
  }

  private fetchRequests() {
    this.sub.add(
      this.requestService.getExecutorRequests(this.user.id, this.data.exeStatus).subscribe(requests => {
        this.toTableConfig(requests);
      })
    )
  }

  private toTableConfig(dataArr: Request[]): void {
    const config: IDataTableConfig = {
      data: dataArr.map(data => {
        const p: ITableData = cloneDeep(data);
        p.title = data.title;
        p.customerName = `${data.management.customer.firstname} ${data.management.customer.lastname}`
        p._metadata = {
          originalData: data
        }
        return p;
      }),
      displayProperties: ['title', 'customerName'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
    }
    this.tableCfg = config;
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
